import React from "react";

const SpinnerType = (props) => {
  return (
    <>
      <div className={"submit-spinner " + (props.spinning? "d-block": "d-none")}>
        <div className="spinner-overlay"></div>
        <div className="wrapper-spinner">
        {props.content}
        </div>
      </div>
    </>
  )
}

const Spinners = (props) => {
  return ( 
    <>
    {props.spinning? (
      <SpinnerType spinning={props.spinning} content={props.content} />
    ): null}
    </>
  );
}
 
export default Spinners;