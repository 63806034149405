import {
  FETCH_CARTS,
  PUSH_CARTS,
  UPDATE_CARTS,
  REMOVE_CARTS,
  CLEAR_CARTS,
 } from '../actions/cartsActions';
 import cloneDeep from "lodash/cloneDeep"

const INITIAL_STATE = {
  data: [],
}

export default function cartsReducer(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_CARTS:
      return {
        ...state,
        data:action.data,
      }
    case PUSH_CARTS:
      // let findInCarts = find(state.data, { 'productId': action.data._id })
      // if(findInCarts){
      //     let updated = state.data.map((v)=>{
      //         if(v.productId === action.data._id) {
      //           let productQuantity = (v.quantity + (action.quantity))
      //           v.quantity = productQuantity
      //           v.totalNetAmount = v.perUnitAmount*(productQuantity)
      //           return v
      //         } else return v
      //     })
      //     return {
      //         ...state,
      //         data:updated,
      //     }
      // }else{
          let addtoCarts = {
            productId: action.data._id,
            productCode: action.data.code,
            productImg: action.data.productImg[0]?action.data.productImg[0].fileUrl:'',
            name: action.data.name,
            quantity: (action.quantity),
            totalNetAmount: action.data.netAmount*(action.quantity),
            perUnitAmount: action.data.netAmount,
            variations: action.data.variations?
              (action.data.variations.length > 0?
                cloneDeep(action.data.variations).filter(element => {
                  if(element.selected){
                    element.totalAmount = element.amount*(action.quantity)
                    return element
                  }else{return false}
                })
                :[])
            :[],
            localShippingAmount: action.data.localShippingAmount,
            packageHeight: action.data.packageHeight,
            packageLength: action.data.packageLength,
            packageWeight: action.data.packageWeight,
            packageWidth: action.data.packageWidth
          }
          return {
              ...state,
              data:[addtoCarts, ...state.data],
          }
      // }
    case UPDATE_CARTS:
      let updated = state.data.map((v, i)=>{
        if(i === action.index) {
          let productQuantity = 0
          if(action.symbol === "+"){
            productQuantity = (v.quantity + (action.quantity))
          }else{
            productQuantity = (v.quantity - (action.quantity))
          }
          v.quantity = productQuantity
          v.totalNetAmount = v.perUnitAmount*(productQuantity)
          v.variations = v.variations?
              (v.variations.length > 0?
                cloneDeep(v.variations).filter(element => {
                  if(element.selected){
                    element.totalAmount = element.amount*(productQuantity)
                    return element
                  }else{return false}
                })
                :[])
            :[]
          return v
        } else return v
      })
      return {
          ...state,
          data:updated,
      }
    case REMOVE_CARTS:
      return {
        ...state,
        data: [...state.data.filter( (item, index) => index !== action.index)]
      }
    case CLEAR_CARTS:
      return {
        ...state,
        data: []
      }
    default:
      return state

  }
}