import {
  FETCH_COMPANY,
 } from '../actions/companyActions';

const INITIAL_STATE = {
  data: {
    rateUSDtoMYR: "4.13",
    companyName: "Pracworks",
    companyNumber: "SA0324572-H",
    address: "20, Jalan Penerbit U1/43, Temasya industrial Park, Glenmarie, Seksyen U1, 40150  ShahAlam, Selangor, Malaysia",
    state: "Wilayah Persekutuan (Kuala Lumpur)",
    country: "Malaysia",
    postalCode: "40150",
    phoneNumber: "+60163950893",
    countryCode: "MY",
    personName: "Yun Kit",
    stateOrProvinceCode: "14"
  },
}

export default function companyReducer(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_COMPANY:
      return {
        ...state,
        data:action.data,
      }
    default:
      return state

  }
}