import React from "react";
import isEmpty from "lodash/isEmpty"
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import {
  FormGroup,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// import FedexLogo from '../../assets/images/fedex-logo.svg'
// import UpsLogo from '../../assets/images/ups-logo.svg'

const validationSchema = Yup.object().shape({
  address: Yup.string()
    .required('Address is required'),
  country: Yup.string()
    .required('Country is required'),
  state: Yup.string()
    .required('State is required'),
  postalCode: Yup.string()
    .required('PostalCode is required'),
})

const ShippingInfo = (props) => {

  const selectCountry = (val,  setFieldValue) => {
    let code = CountryRegionData.find(e => e[0] === val)[1]
    setFieldValue("country", val)
    setFieldValue("countryCode", code)
    setFieldValue("state", '')
    setFieldValue("stateOrProvinceCode", '')
  }

  const selectState = (val, country, setFieldValue) => {
    let regionData = (CountryRegionData.find(e => e[0] === country)[2]).split("|")
    let stateOrProvinceCode = regionData.find(e => e.split('~')[0] === val)
    setFieldValue("state", val)
    setFieldValue("stateOrProvinceCode", stateOrProvinceCode.split('~')[1])
  }
  
  return (  
    <>
      <ul className="mb-3">
        <li className="d-inline-block"><a href="/viewcart">
            Cart
          </a>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span className="font-weight-bold">Shipping Address</span>  
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}}>Shipping Amount</span>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}}>Information</span>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}}>Payment</span>
        </li>
      </ul>
      <Formik
        initialValues={{
          address: !isEmpty(props.formValue)
            ?props.formValue.address
            :(props.userInfo.address?props.userInfo.address:""),
          country: props?.formValue?.country || "",
          countryCode: props.formValue.countryCode || "",
          stateOrProvinceCode: !isEmpty(props.formValue)
            ?props.formValue.stateOrProvinceCode
            :(props.userInfo.stateOrProvinceCode?props.userInfo.stateOrProvinceCode:""),
          state: !isEmpty(props.formValue)
            ?props.formValue.state
            :(props.userInfo.state?props.userInfo.state:""),
          postalCode: !isEmpty(props.formValue)
            ?props.formValue.postalCode
            :(props.userInfo.postalCode?props.userInfo.postalCode:""),
        }}
        validationSchema={validationSchema}
        onSubmit={props.onSubmit}
        render={({ values, errors, setFieldValue, touched }) => (
          <Form>
            <h3 className="mt-4">Shipping Address</h3>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="address">Address</label>
                  <Field 
                    type="text" 
                    name="address" 
                    placeholder="Address" 
                    className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}  
                    />
                  <ErrorMessage name="address" component="div" className="invalid-feedback" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="country">Country</label>
                  <CountryDropdown
                    value={values.country}
                    name="country" 
                    classes={'form-control' + (errors.country && touched.country ? ' is-invalid' : '')}  
                    onChange={(val) => selectCountry(val, setFieldValue)} 
                    />
                  <ErrorMessage name="country" component="div" className="invalid-feedback" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="region">Region</label>
                  <RegionDropdown
                    country={values.country}
                    value={values.state}
                    classes={'form-control' + (errors.state && touched.state ? ' is-invalid' : '')}  
                    onChange={(val) => selectState(val, values.country, setFieldValue)} />
                  <ErrorMessage name="region" component="div" className="invalid-feedback" />
                </div>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="postalCode">Postal code</label>
                  <Field 
                    type="text" 
                    name="postalCode" 
                    placeholder="PostalCode" 
                    className={'form-control' + (errors.postalCode && touched.postalCode ? ' is-invalid' : '')}  
                    />
                  <ErrorMessage name="postalCode" component="div" className="invalid-feedback" />
                </FormGroup>
              </div>
            </div>
            <FormGroup className="mt-3">
              {/* <a href="/summary"> */}
                {props.spin?(
                  <button className="button" type="button" disabled>
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
                ):(
                  <button type="submit" className="button">
                    <span>Calculate Shipping Cost</span>
                  </button>
                )}
              {/* </a> */}
            </FormGroup>
          </Form>
          )}
        />
    </>
  );
}
 
export default ShippingInfo;