import React from "react";

import CarbonPNG from "../../assets/images/products/47577235_403291563543962_8028839317126250496_n.png"

const About = () => {
  return (  
    <>  
      <div className="main-row full-width" id="about">
        <div className="container">
          <div className="row">
            <div className="main-col col-sm-12 col-md-12">
              <div className="row sub-row">
                <div className="sub-col col-sm-12 col-md-12">
                  <div className="static-welcome">
                    <div className="title">
                      {/* <h1></h1> */}
                      <h2>ABOUT US</h2>
                      {/* <h3>
                        Pracworks Carbon was founded in 2013
                      </h3> */}
                    </div>
                    <div className="content">
                      <div className="image">
                        <img src={CarbonPNG} alt="pracworks carbon" />
                      </div>
                      <div className="text">
                        <h3>
                          {/* <span className="word1">meet our</span> */}
                          <span className="word2">PRACWORKS CARBON SINCE 2013</span><span
                            className="word3">Design and Manufacture</span>
                        </h3>
                        <p>
                          Pracworks Carbon was founded in 2013 with the aim to engineer 
                          and manufacture the ultimate carbon fiber products at great 
                          prices that combines form, function and aesthetics all in one 
                          package for car enthusiasts all the way up to professional 
                          race teams.
                        </p>
                        <p>
                          All products are manufactured utilizing state of the art CNC Machines and skilled craftsmen hand laying every single Carbon Fiber Layer to ensure the highest quality is achieved.
                        </p>
                        {/* <div className="author">
                          <h4 className="name">John doe - CEO aero</h4>
                          <img className="signature" src="//cdn.shopify.com/s/files/1/3012/8606/files/img2.png?v=1519511476"
                            alt="" />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default About;