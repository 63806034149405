
// GRECAPTCHA_V3_KEY
// local
// const key = '6LfKpBUaAAAAANwEgRWIwcAIjpa2YLuJeCbaEKRQ'
// stage
// const key = '6LdpjMEaAAAAAOwasQISs60BAbLAoO1DhZUcBB30'
// live
const key = '6LeLM_UaAAAAANhy-OYD6CXtYVMDRmo9H3hy3h5e'

// mails to
const mailTo = [
  'Inquiries@pracworks.com.my'
]

// Paypal key
// stage
// const paypalClientID = "AQxzOrRZviUOu5yR4kSmy2OSkciNUgKlvSMumHtcXwRQQM275-IECvo2TYkecH5teeGh00Iql1B9hucU"
// live
const paypalClientID = "AaSuZDZzMvYyxgisQ3aOkTs7goWgW9kpGJAMrxh-QIvnfdtM5vHOwjHSiQJ8hKyurBkJyl7N8zIz5-9N"

const env = {
  GRECAPTCHA_V3_KEY: key,
  mailTo: mailTo,
  paypalClientID: paypalClientID,
}

export default env;