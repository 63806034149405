import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchCompany } from '../redux/actions/companyActions';
import { clearCarts } from '../redux/actions/cartsActions';
import sumBy from "lodash/sumBy"
import isEmpty from "lodash/isEmpty"
import cloneDeep from "lodash/cloneDeep"
import axios from "axios";
import Cookies from 'js-cookie';
import {
  Row,
  Col,
  Container,
} from 'reactstrap';

import Alerts from "../components/Extra/Alerts.js"
import Calculate from "../components/Checkout/Calculate.js";
import ShippingInfo from "../components/Checkout/ShippingInfo.js";
import Infomation from "../components/Checkout/Infomation.js";
import Shipping from "../components/Checkout/Shipping.js";
import Payment from "../components/Checkout/Payment.js";

import client from "../feathers.js"

import PracWorksLogo from '../assets/images/navbarLogo.png'

const Checkout = (props) => {
  const [ formValue, setFormValue ] = useState({})
  const [ activeIndex, setActiveIndex ] = useState(0)
  const [ shippingFee, setShippingFee ] = useState(0)
  const [ spin, setSpin ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('danger')
  const [ upsServices, setUpsServices ] = useState([])
  const [ fedexServices, setFedexServices ] = useState([])
  const [ dhlServices, setDhlServices ] = useState([])
  const [ fedExSpin, setFedExSpin ] = useState(false)
  const [ upsSpin, setUpsSpin ] = useState(false)
  const [ dhlSpin, setDhlSpin ] = useState(false)

  useEffect(() => {
    if(props.carts.length <= 0){
      props.history.push('/products')
    }
  })
  
  function isCookieExpired(name) {
    var cookieValue = Cookies.get(name);
    if (!cookieValue) {
        return true; // Cookie doesn't exist
    }
    var cookieExpiresAt = Cookies.get('upsToken_expiry');

    // Calculate the time after 14,399 seconds (4 hours)
    var futureDate = new Date(new Date().getTime() + (cookieExpiresAt * 1000)); // Convert seconds to milliseconds
    
    return new Date() > futureDate;
  }

  function getOrSetCookie(){
    var existingCookie = Cookies.get('upsToken');
    if (!existingCookie || isCookieExpired("upsToken")) {
        axios.post(`${client.io.io.uri}ups/v1/get/token`)
        .then(res => {
          if(res?.data?.access_token){
            let expiry_data = res?.data?.expires_in / 86400 // Convert seconds to days
            // Cookie doesn't exist or is expired, set new cookie
            Cookies.set('upsToken', res?.data?.access_token, { expires: expiry_data });
            Cookies.set('upsToken_expiry', res?.data?.expires_in, { expires: expiry_data });
            return res?.data?.access_token;
          }
        })
        .catch(err => console.log(err))
    } else {
        // Cookie exists and is not expired, return old cookie value
        return existingCookie;
    }
  }

  useEffect(() => {
    getOrSetCookie()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchCompanyInfo = (fetchCompany) => {
      client.service('companies').find({
        query: {
          $limit: 1,
        }
      })
      .then((res)=>{
        fetchCompany(res.data[0])
      })
    }
    fetchCompanyInfo(props.fetchCompany)
  }, [props.fetchCompany])

  const onSubmit = (value) => {
    setSpin(true)
    setFormValue(value)

    let packageCount = sumBy(props.carts, 'quantity')

    let stateOrProvinceCode = (value.stateOrProvinceCode).length >= 2? (value.stateOrProvinceCode).substring(0, 2): value.stateOrProvinceCode

    let params = {
      username: 'EntreShip',
      password: '2bea72d97fd428ac90366f6b2f6118a33f4f2ce15b10eac65ad1081aa589bcf9',
      email: 'sender@customer.com',
      firstName: 'firstName',
      lastName: 'lastName',
      address: value.address,
      country: value.country,
      state: value.state,
      postalCode: value.postalCode,
      phoneNumber: 'phoneNumber',
      countryCode: value.countryCode,
      stateOrProvinceCode: stateOrProvinceCode,
      packageLineItem: [],
      packageCount: packageCount
    }

    let params1 = cloneDeep(params)
    let params2 = cloneDeep(params)
    
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      }
    }
    
    // ignore if ship local
    // sum localShippingAmount from products
    if(value.countryCode === "MY"){
      if(props.carts.length > 0){

        value.serviceCode = ''
        value.shippingCompany = ""
        setFormValue(value)

        // local shipping amount
        if(props.currency === "USD"){
          setShippingFee(
            Number(((sumBy(props.carts, "localShippingAmount") * sumBy(props.carts, "quantity"))/props.rateUSDtoMYR).toFixed(2).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
          )
        }else{
          setShippingFee(
            Number((sumBy(props.carts, "localShippingAmount") * sumBy(props.carts, "quantity")).toFixed(2).toLocaleString("ms-MY", { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
          )
        }
        handleOpen(false, "danger", "")
        setActiveIndex(1)
        setSpin(false)
      }
    }else{
      
      console.log("Original stateOrProvinceCode: " + (value.stateOrProvinceCode))
      console.log("stateOrProvinceCode: " + (stateOrProvinceCode))

      setFedExSpin(true)
      setUpsSpin(true)
      setDhlSpin(true)

        let packageLineItem1 = cloneDeep(props.carts).map((v, i) => {
          return {
            "PackagingType": {
              "Code": "02",
              "Description": "Packaging"
            },
            "PackageWeight": {
              "UnitOfMeasurement": {
                  "Code": "KGS",
                  "Description": "Kilogram"
              },
              "Weight": (v.quantity * v.packageWeight)?.toString()
            },
            "Dimensions": {
              "UnitOfMeasurement": {
                "Code": "CM",
                "Description": "Centimeters"
              },
              "Length": v.packageLength?.toString(),
              "Width": v.packageWidth?.toString(),
              "Height": v.packageHeight?.toString()
            },
            "OversizeIndicator": "X",
            "MinimumBillableWeightIndicator": "X"
          }
        })
        
        let invoice_line_total = {
          monetary_value: 0,
          currency_code: props.currency
        }
        if(props.currency === "USD"){
          invoice_line_total.monetary_value = (sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount")))).toFixed(2)
        }else{
          invoice_line_total.monetary_value = ((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount"))))*props.rateUSDtoMYR).toFixed(2)
        }
        
        params.invoice_line_total = invoice_line_total
        params.packageLineItem = packageLineItem1
        params.token = Cookies.get('upsToken')

        // Ups rating api call
        // axios.post(`${client.io.io.uri}getUpsRates`, params, config)
        axios.post(`${client.io.io.uri}ups/v1/get/rating`, params, config)
        .then(res => {
          
          let RatedShipment = res?.data?.RateResponse?.RatedShipment || []
          
          RatedShipment.map(value => {
            value.shippingCompany = "ups"
            return value
          })
          RatedShipment.map(value => {
            // if(value.Service.Code === "07" || value.Service.Code === "65"){
            //   value.TotalCharges.MonetaryValue = Number(value.TotalCharges.MonetaryValue) * 1.4
            // }

            // after sum
            if(value.Service.Code === "08"){
              // 0.46
              value.TotalCharges.MonetaryValue = Number(value.TotalCharges.MonetaryValue) * 0.46
            }
            if(props.currency === "USD"){
              value.TotalCharges.MonetaryValue = Number(Number(value.TotalCharges.MonetaryValue) / props.rateUSDtoMYR).toFixed(2)
            }
            return value
          })
          
          setUpsServices(RatedShipment)

          setActiveIndex(1)
          handleOpen(false, "danger", "")
          setSpin(false)
          setUpsSpin(false)
        }).catch(err => {
          console.log(err)
          handleOpen(true, "danger", "Something went wrong! or address not available!.")
          setSpin(false)
          setUpsSpin(false)
        });

        // FedEx rating api call
        // axios.post('http://localhost/fedex-shipping-api/api/fetchRates', params, config)

        let packageLineItem2 = cloneDeep(props.carts).map((v, i) => {
          return {
            SequenceNumber: 1+1,
            GroupPackageCount: v.quantity,
            Weight: {
              Units: 'KG',
              Value: v.packageWeight
            },
            Dimensions: {
              Length: v.packageLength || 0,
              Width: v.packageWidth  || 0,
              Height: v.packageHeight || 0,
              Units: 'CM'
            }
          }
        })

        params1.packageLineItem = packageLineItem2

        axios.post(`${client.io.io.uri}getFedexRates`, params1, config)
        .then(res => {
          // console.log(res.data)

          let fedexService = [{
            ServiceType: "INTERNATIONAL_PRIORITY",
            Service: {
              Code: "fedEx-INTERNATIONAL_PRIORITY"
            },
            TotalCharges: {
              CurrencyCode: props.currency,
              MonetaryValue: "0"
            },
            shippingDiscPercent: 0,
            shippingDiscAmount: 0
          },
          {
            ServiceType: "INTERNATIONAL_ECONOMY",
            Service: {
              Code: "fedEx-INTERNATIONAL_ECONOMY"
            },
            TotalCharges: {
              CurrencyCode: props.currency,
              MonetaryValue: "0"
            },
            shippingDiscPercent: 0,
            shippingDiscAmount: 0
          }]

          res?.data?.map(x => {
            if(!isEmpty(x.RateReplyDetails?.[0].RatedShipmentDetails?.[1])){
            // if(!isEmpty(x.RateReplyDetails[0].RatedShipmentDetails[1]) && !isEmpty(x.RateReplyDetails[0].RatedShipmentDetails[0])){
            // if(x.HighestSeverity === "SUCCESS" || x.HighestSeverity === "WARNING"){
              fedexService.map(x1 => {
                if(x.RateReplyDetails[0].ServiceType === x1.ServiceType){
                  if(props.currency === "USD"){
                    if(x.RateReplyDetails[0].RatedShipmentDetails[1].ShipmentRateDetail.TotalNetFedExCharge.Currency === "USD"){
                      let shippingAmount = (x.RateReplyDetails[0].RatedShipmentDetails[1].ShipmentRateDetail.TotalNetFedExCharge.Amount) / 1.1
                      
                      x1.shippingDiscPercent = x.RateReplyDetails[0].RatedShipmentDetails[1].ShipmentRateDetail.FreightDiscounts[0].Percent
                      x1.shippingDiscAmount = x.RateReplyDetails[0].RatedShipmentDetails[1].ShipmentRateDetail.FreightDiscounts[0].Amount.Amount
                      x1.TotalCharges.MonetaryValue = shippingAmount
                      
                    }
                  }else{
                    if(x.RateReplyDetails[0].RatedShipmentDetails[0].ShipmentRateDetail.TotalNetFedExCharge.Currency === "MYR"){
                      let shippingAmount = (x.RateReplyDetails[0].RatedShipmentDetails[0].ShipmentRateDetail.TotalNetFedExCharge.Amount) / 1.1
                      
                      x1.shippingDiscPercent = x.RateReplyDetails[0].RatedShipmentDetails[0].ShipmentRateDetail.FreightDiscounts[0].Percent
                      x1.shippingDiscAmount = x.RateReplyDetails[0].RatedShipmentDetails[0].ShipmentRateDetail.FreightDiscounts[0].Amount.Amount
                      x1.TotalCharges.MonetaryValue = shippingAmount
                      
                    }
                  }
                }
                return x1
              })
            }else{
              console.log(x.Notifications)
            }
            return x
          })

          fedexService.map((x, i) => {
            x.shippingCompany = "fedex"
            if(x.TotalCharges.MonetaryValue !== "0"){
            }else{
              delete fedexService[i]
            }
            return x
          })

          // console.log(fedexService)

          setFedexServices(fedexService)

          setActiveIndex(1)
          handleOpen(false, "danger", "")
          setSpin(false)
          setFedExSpin(false)
        }).catch(err => {
          console.log(err)
          handleOpen(true, "danger", "Something went wrong! or address not available!.")
          setSpin(false)
          setFedExSpin(false)
        });


        // DHL rating api call
        let packageLineItem3 = cloneDeep(props.carts).map((v, i) => {
          return {
            weight: v.quantity * v.packageWeight,
            dimensions: {
              length: Number(v.packageLength),
              width: Number(v.packageWidth),
              height: Number(v.packageHeight),
            }
          }
        })
        let monetaryAmount = {
          "typeCode": "declaredValue",
          value: 0,
          currency: props.currency
        }
        if(props.currency === "USD"){
          monetaryAmount.value = Number((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount")))).toFixed(2))
        }else{
          monetaryAmount.value = Number(((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount"))))*props.rateUSDtoMYR).toFixed(2))
        }
        params2.monetaryAmount = [monetaryAmount]
        params2.packageLineItem = packageLineItem3
        axios.post(`${client.io.io.uri}getDHLRates`, params2, config)
        .then(res => {

          res.data.products.map(value => {
            value.shippingCompany = "dhl"
            value.TotalCharges = {
              MonetaryValue: Number(value.totalPrice[0]?.price)
            }
            value.Service = {
              Code: value.productCode
            }
            return value
          })

          // if MYR no convert
          // convert only if USD
          if(props.currency === "USD"){
            res.data.products.map(value => {
              value.Service = {
                Code: value.productName
                // Code: value.productCode
              }
              value.TotalCharges.MonetaryValue = Number((Number(value.totalPrice[0]?.price) / props.rateUSDtoMYR).toFixed(2))
              return value
            })
          }
          
          setDhlServices(res.data.products)

          setActiveIndex(1)
          handleOpen(false, "danger", "")
          setSpin(false)
          setDhlSpin(false)
        }).catch(err => {
          console.log(err)
          handleOpen(true, "danger", "Something went wrong! or address not available!.")
          setSpin(false)
          setDhlSpin(false)
        });
        
    }
  }

  const onSubmitShipping = (value) => {
    setActiveIndex(2)
  }

  const onSubmitShippingInfo = (value) => {
    let result = formValue
    result.email = value.email
    result.firstName = value.firstName
    result.lastName = value.lastName
    result.phoneNumber = value.phoneNumber

    setFormValue(result)
    setActiveIndex(3)
  }

  const _RenderActiveIndex = () => {
    if(activeIndex === 1){
      return (
        <Shipping 
          formValue={formValue}
          setActiveIndex={setActiveIndex}
          shippingFee={shippingFee}
          dhlServices={dhlServices}
          upsServices={upsServices}
          fedexServices={fedexServices}
          setShippingFee={setShippingFee}
          setFormValue={setFormValue}
          currency={props.currency}
          rateUSDtoMYR={props.rateUSDtoMYR}
          onSubmitShipping={onSubmitShipping}
          fedExSpin={fedExSpin}
          upsSpin={upsSpin}
          dhlSpin={dhlSpin}
        />
      )
    }else if(activeIndex === 2){
      return (
        <Infomation 
          formValue={formValue}
          setActiveIndex={setActiveIndex}
          userInfo={props.userInfo} 
          onSubmit={onSubmitShippingInfo}
          />
      )
    }else if(activeIndex === 3){
      return (
        <Payment 
          formValue={formValue}
          setActiveIndex={setActiveIndex}
          carts={props.carts}
          currency={props.currency}
          rateUSDtoMYR={props.rateUSDtoMYR}
          shippingFee={shippingFee}
          clearCarts={props.clearCarts}
          handleOpen={handleOpen}
          userId={props.userId}
          companyInfo={props.companyInfo}/>
      )
    }else{
      return (
        <ShippingInfo 
          formValue={formValue}
          userInfo={props.userInfo} 
          onSubmit={onSubmit}
          spin={spin}
          />
      )
    }
  }

  const handleOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }

  return (  
    <>
      {/* <NavBar /> */}
        <main>
          <Row className="w-100 no-gutters">
            <Col md="7" style={{height:'100vh', overflow: 'auto'}}>
              <div style={{padding:'40px 0px'}}>
                <Container className="pl-md-5 pr-md-5">
                  <a href="/" style={{cursor:'pointer'}} className="d-block text-center m-auto pb-3">
                    <img 
                      src={PracWorksLogo} 
                      style={{width: 250}}
                      alt="pracWorks Carbon" 
                    />
                  </a>
                  <Alerts isOpen={isOpen} handleOpen={handleOpen} color={color} message={message} />
                  <h1>Shopping Cart</h1>
                  {_RenderActiveIndex()}
                </Container>
              </div>
            </Col>
            <Col md="5" style={{background:'#F0F2F5', height:'100vh'}}>
              <div style={{padding:'100px 0px'}}>
                <Container className="pl-md-5 pr-md-5">
                  <Calculate 
                    carts={props.carts}
                    currency={props.currency}
                    rateUSDtoMYR={props.rateUSDtoMYR}
                    shippingFee={shippingFee} />
                </Container>
              </div>
            </Col>
          </Row>
        </main>
      {/* <Footer /> */}
   </>
  );
}
 

const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  userInfo: state.role.details.user?state.role.details.user:{},
  carts: state.carts.data,
  companyInfo: state.company.data,
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
  currency: state.setting.currency
});

const mapDispatchToProps = {
  fetchCompany: fetchCompany,
  clearCarts: clearCarts
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);