import React from "react";

import PaymentMethod from "../../assets/images/payment.png"

import PracWorksLogo from '../../assets/images/17884401_130907670782354_5641047300408986097_n_footer (1).png'

const Footer = () => {
  return (  
    <>
      {/* End Quickview Product */}
      <div className="section-footer">{/*Footer Start*/}
        <footer
          style={{backgroundImage: 'url("//cdn.shopify.com/s/files/1/3012/8606/files/bkg_footer.jpg?v=1519662805")'}}>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col1 col-md-4 col-sm-12 col-footer">
                  <div className="footer-title">
                    <h3>translation missing: en.layout.footer.about_us</h3>
                  </div>
                  <div className="footer-content">
                    <a href="/" itemProp="url" className="logo-footer">
                      <img 
                        src={PracWorksLogo} 
                        style={{width: 192}}
                        alt="pracWorks Carbon" 
                      />
                    </a>
                    <p className="des">
                      Manufacturer of High Quality Carbon Fiber Intake Manifold and Automotive Parts.
                    </p>
                    <ul className="footer-contact">
                      {/* <li className="">
                        <i className="fa fa-map-marker"></i>
                        <span>Address :</span>No 40 Baria Sreet 133/2, NewYork, USA.
                      </li> */}
                      <li className="phone">
                        <i className="fa fa-phone"></i>
                        <span>Phone :</span>+(60) 16-395 0893
                      </li>
                      <li className="email">
                        <i className="fa fa-envelope-o"></i>
                        <span>Email :</span>Inquiries@pracworks.com.my
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6 col-footer">
                  <div className="footer-title">
                    <h3>Main menu</h3>
                  </div>
                  <div className="footer-content">
                    <ul className="list-unstyled text-content">
                      <li><a href="/">Home</a></li>
                      <li><a href="/#about">About Us</a></li>
                      <li><a href="/products">Shop</a></li>
                      {/* <li><a href="/#blogs">Blogs</a></li> */}
                      <li><a href="/#contact">Contact Us</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6 col-footer">
                  <div className="footer-title">
                    <h3>Products</h3>
                  </div>
                  <div className="footer-content">
                    <ul className="list-unstyled text-content">
                      <li><a href="/products">All Products</a></li>
                      {/* <li><a href="/products">Honda K-series Intake manifold</a></li>
                      <li><a href="/products">Honda B-series Intake Manifold</a></li>
                      <li><a href="/products">Honda F-series Intake Manifold</a></li>
                      <li><a href="/products">Accessory</a></li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6 col-footer">
                  <div className="footer-title">
                    <h3>About Us</h3>
                  </div>
                  <div className="footer-content">
                    <ul className="list-unstyled text-content">
                      <li><a href="/#about">About</a></li>
                      <li><a href="/policy">Privacy Policy</a></li>
                      <li><a href="/terms">Terms Of Service</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col4 col-md-2 col-sm-6 col-footer">
                  <div className="footer-title">
                    <h3>Follow Us</h3>
                  </div>
                  <div className="footer-content">
                    <ul className="list-unstyled text-content">
                      <li><a href="https://www.facebook.com/PracWorksCarbon/">Facebook</a></li>
                      <li><a href="https://www.instagram.com/pracworks_carbon/">Instagram</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="container-inner">
                <div className="footer-copyright">
                  <span>Copyright © 2021 <a target="_blank" rel="noreferrer" href="https://www.facebook.com/EntreShipSol/">EntreShip Sol.</a> All rights
                    reserved.</span>
                </div>
                <ul className="policy-term">
                  <li className="first">
                    <a href="/policy">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="/terms">
                      Terms Of Service
                    </a>
                  </li>
                </ul>
                <ul className="link-follow">
                  <li className="first">
                    <a className="fa fa-whatsapp" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=60163950893">
                    </a>
                  </li>
                  <li>
                    <a className="fa fa-facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/PracWorksCarbon/">
                    </a>
                  </li>
                  <li>
                    <a className="fa fa-instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/pracworks_carbon/">
                    </a>
                  </li>
                </ul>
                <div className="footer-payment">
                  {/* <a href="/#"> */}
                    <img src={PaymentMethod} alt="pracworks carbon" />
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
          <div id="back-top" style={{display: 'none'}}><i className="fa fa-angle-up" /></div>
        </footer>
      </div>
    </>
  );
}
 
export default Footer;