import React from "react";

import {
  FormGroup,
  Media
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  phoneNumber: Yup.string()
    .required('Contact No. is required'),
})

const Infomation = (props) => {

  return (  
    <>
      <ul className="mb-3">
        <li className="d-inline-block"><a href="/viewcart">
            Cart
          </a>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}} onClick={() => props.setActiveIndex(0)}>Shipping Address</span>  
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}} onClick={() => props.setActiveIndex(1)}>Shipping Amount</span>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span className="font-weight-bold">Information</span>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}}>Payment</span>
        </li>
      </ul>
      <Formik
        initialValues={{
          email: props.userInfo.email?props.userInfo.email: (props.formValue.email || ''),
          firstName: props.userInfo.firstName?props.userInfo.firstName: (props.formValue.firstName || ''),
          lastName: props.userInfo.lastName?props.userInfo.lastName: (props.formValue.lastName || ''),
          phoneNumber: props.userInfo.phoneNumber?props.userInfo.phoneNumber: (props.formValue.phoneNumber || ''),
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={props.onSubmit}
        render={({ values, errors, setFieldValue, touched }) => (
          <Form>
            <FormGroup className="mt-4">
              <h3>Email</h3>
              <Field 
                type="email" 
                name="email" 
                placeholder="Email" 
                className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}  
                />
              <ErrorMessage name="email" component="div" className="invalid-feedback" />
            </FormGroup>
            <h3>Shipping Address</h3>
            <Media>
              <Media body>
                <FormGroup className="mr-md-3">
                  <label className="form-control-label" htmlFor="firstName">First Name</label>
                  <Field 
                    type="text" 
                    name="firstName" 
                    placeholder="First Name" 
                    className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}  
                    />
                  <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                </FormGroup>
              </Media>
              <Media body>
                <FormGroup>
                  <label className="form-control-label" htmlFor="lastName">Last Name</label>
                  <Field 
                    type="text" 
                    name="lastName" 
                    placeholder="Last Name" 
                    className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}  
                    />
                  <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                </FormGroup>
              </Media>
            </Media>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="address">Address</label>
                  <input 
                    type="text" 
                    placeholder="Address"
                    className="form-control" 
                    value={props.formValue.address}
                    disabled
                    />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="country">Country</label>
                  <input 
                    type="text" 
                    placeholder="Country"
                    className="form-control" 
                    value={props.formValue.country}
                    disabled
                    />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="region">Region</label>
                  <input 
                    type="text" 
                    placeholder="State"
                    className="form-control" 
                    value={props.formValue.state}
                    disabled
                    />
                </div>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="postalCode">Postal code</label>
                  <input 
                    type="text" 
                    placeholder="PostalCode"
                    className="form-control" 
                    value={props.formValue.postalCode}
                    disabled
                    />
                </FormGroup>
              </div>
            </div>
            <FormGroup>
              <label className="form-control-label" htmlFor="phoneNumber">Contact No.</label>
              <Field 
                type="text" 
                name="phoneNumber" 
                placeholder="Contact No." 
                className={'form-control' + (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')}  
                />
              <ErrorMessage name="phoneNumber" component="div" className="invalid-feedback" />
            </FormGroup>
            <FormGroup className="mt-3">
              {/* <a href="/summary"> */}
                {props.spin?(
                  <button className="button" type="button" disabled>
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
                ):(
                  <button type="submit" className="button">
                    <span>Continue to Payment</span>
                  </button>
                )}
              {/* </a> */}
            </FormGroup>
          </Form>
          )}
        />
    </>
  );
}
 
export default Infomation;