import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { fetchProducts, updateProducts } from '../redux/actions/productsActions';
import { pushCarts } from '../redux/actions/cartsActions';
import uniqBy from "lodash/uniqBy"

import NavBar from "../components/NavBar/Main.js"
import Banner from "../components/Index/Banner.js"
import About from "../components/Index/About.js"
import Products from "../components/Index/Products.js"
// import BestProducts from "../components/Index/BestProducts.js"
// import Blogs from "../components/Index/Blogs.js"
import Subscribe from "../components/Index/Subscribe.js"
import Footer from "../components/Footer/Main.js"

// import SubBanner1 from "../assets/images/24177085_223877398152047_2455265600423090720_n (1).jpg"

import client from "../feathers"

const Index = (props) => {

  useEffect(() => {
    async function reFetchData(){
      let reFetch = await fetchData(props.fetchProducts, 0)
      
      if(!reFetch){
        console.log(reFetch)
        setTimeout(async function(){ 
          reFetch = await fetchData(props.fetchProducts, 0)
          if(!reFetch){
            console.log(reFetch)
            setTimeout(async function(){
              reFetch = await fetchData(props.fetchProducts, 0)
              if(!reFetch){ 
                console.log(reFetch)
              setTimeout(async function(){ 
                reFetch = await fetchData(props.fetchProducts, 0)
                if(!reFetch){
                  console.log(reFetch)
                  setTimeout(async function(){ 
                    reFetch = await fetchData(props.fetchProducts, 0)
                    console.log(reFetch)
                  }, 2000);
                }
              }, 2000);
              }
            }, 2000);
          }
        }, 2000);
      }
    }
    reFetchData()
  }, [props.fetchProducts])
  
  const fetchData = (fetchProducts, skip) => {
    return client.service('products').find({
      query: {
        // $skip: skip,
        $limit: 20,
        $sort: {
          dateToFirst: -1
        }
      }
    })
    .then((res) => {
      let result = res.data.map(v => {
        if(v.variations){
          if(v.variations.length > 0){

            let uniqTypes = uniqBy(v.variations, "type")
            v.variations.map(v1 => {
              uniqTypes.map(uniType => {
                if(uniType._id === v1._id){
                  v1.selected = true
                  return v1
                }else{return v1}
              })
              return v1
            })
            return v
            
          }else{return v}
        }else{return v}
      })
      fetchProducts(result)
      return true
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        console.log(true, 'danger', "Please Sign-in to continue!")
        return true
      }else if(err.name === "Timeout"){
        return false
      }else{
        console.log(true, 'danger', err.message)
        return true
      }
    })
  }

  const addToCart = (v, quantity) => {
    props.pushCarts(v, quantity)
  }

  return ( 
    <>
     <NavBar />
      <main id="content">
        <Banner />
        {/* <div className="index-section index-section--flush">
          <div className="main-row ">
            <div className="container">
              <div className="banner-static static-bottom-aero1 alt">
                <div className="row">
                  <div className="col-md-6 py-1">
                    <div className="image">
                      <a href="/collections/all">
                        <img src={SubBanner1} alt="pracworks carbon" />
                        <span className="text">
                          <span className="text1">new arivals</span>
                          <span className="text2">Good help &amp; Guard</span>
                          <span className="text3">Duis autem vel eum iriure dolor in hendrerit in vulputate velit
                            esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros</span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 py-1">
                    <div className="image">
                      <a href="/collections/all">
                        <img src={SubBanner1} alt="pracworks carbon" />
                        <span className="text">
                          <span className="text1">bestseller products</span>
                          <span className="text2">Top 10 vehicles off week</span>
                          <span className="text3">Duis autem vel eum iriure dolor in hendrerit in vulputate velit
                            esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="index-section--flush">
          <div className="main-row ">
            <div className="container">
              <div className="row">
                <div className="main-col col-sm-12 col-md-12">
                  <div className="row sub-row">
                    <div className="sub-col col-sm-12 col-md-12">
                      <div className="banner-static static-top-aero1">
                        <div className="row">
                          <div className="col col-sm-4 col-xs-12">
                            <div className="image">
                              <a href="/collections/all">
                                <img src="//cdn.shopify.com/s/files/1/3012/8606/files/img1-top-aero1.jpg?v=1519512519" alt="" />
                                <span className="text">
                                  <span className="text1">new collection</span>
                                  <span className="text2">New trending 2017 - 2018</span>
                                  <span className="text3">sale up to 20% off</span>
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="col col-sm-4 col-xs-12">
                            <div className="image">
                              <a href="/collections/all">
                                <img src="//cdn.shopify.com/s/files/1/3012/8606/files/img2-top-aero1.jpg?v=1519512592" alt="" />
                                <span className="text">
                                  <span className="text1">best Low price</span>
                                  <span className="text2">High performance</span>
                                  <span className="text3">sale up to 10% off</span>
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="col col-sm-4 col-xs-12">
                            <div className="image">
                              <a href="/collections/all">
                                <img src="//cdn.shopify.com/s/files/1/3012/8606/files/img3-top-aero1.jpg?v=1519512646" alt="" />
                                <span className="text">
                                  <span className="text1">Honda motorcycles</span>
                                  <span className="text2">Red Sale ends april 30</span>
                                  <span className="text3">sale up to 40% off</span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Products 
          updateProducts={props.updateProducts}
          productsData={props.productsData} 
          currency={props.currency}
          rateUSDtoMYR={props.rateUSDtoMYR}
          addToCart={addToCart}
        />
        <About />
        {/* <BestProducts /> */}
        {/* <Blogs /> */}
      </main>
      {/* End Content */}
      <Subscribe companyInfo={props.companyInfo}/>
      <Footer />
    </>
  );
}
 

const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  productsData: state.products.data,
  companyInfo: state.company.data,
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
  currency: state.setting.currency
});

const mapDispatchToProps = {
  fetchProducts: fetchProducts,
  updateProducts: updateProducts,
  pushCarts: pushCarts
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);