import React from "react";
import moment from 'moment';

// reactstrap components
import {
  Button,
  Table,
  Card,
  CardHeader,
  Modal,
  Media
} from "reactstrap";

const InvoiceModalView = (props) => {

  return (  
    <>
      <Modal
        className="modal-dialog-centered product-modal"
        isOpen={props.modalVisible}
        toggle={props.toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            View Invoice
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
          <div className="modal-body">
            <div className="mb-3">
              <Media>
                <Media body>
                  Email:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.email}
                </Media>
              </Media>
              <Media>
                <Media body>
                  First Name:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.firstName}
                </Media>
              </Media>
              <Media>
                <Media body>
                  Last Name:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.lastName}
                </Media>
              </Media>
            </div>
            <div className="mb-3">
              <Media>
                <Media body>
                  Country:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.country}
                </Media>
              </Media>
              <Media>
                <Media body>
                  Address:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.address}
                </Media>
              </Media>
              <Media>
                <Media body>
                  State:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.state}
                </Media>
              </Media>
              <Media>
                <Media body>
                  Postal Code:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.postalCode}
                </Media>
              </Media>
            </div>
            <div className="mb-3">
              <Media>
                <Media body>
                  Total Items Amount:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.currency === "USD"?
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.dataEdit.totalItemsAmount)
                    :
                    new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(props.dataEdit.totalItemsAmount)
                    }
                </Media>
              </Media>
              <Media>
                <Media body>
                  Shipping Amount:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.currency === "USD"?
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.dataEdit.shippingAmount)
                    :
                    new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(props.dataEdit.shippingAmount)
                    }
                </Media>
              </Media>
              <Media>
                <Media body>
                  Total Amount:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.currency === "USD"?
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.dataEdit.totalAmount)
                    :
                    new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(props.dataEdit.totalAmount)
                    }
                </Media>
              </Media>
            </div>
            <div className="mb-3">
              <Media>
                <Media body>
                  Shipping Discount Amount:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.currency === "USD"?
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.dataEdit.shippingDiscAmount?props.dataEdit.shippingDiscAmount:0)
                    :
                    new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(props.dataEdit.shippingDiscAmount?props.dataEdit.shippingDiscAmount:0)
                    }
                </Media>
              </Media>
              <Media>
                <Media body>
                  Shipping Discount Percent:
                </Media>
                <Media body className="text-right">
                  {props.dataEdit.shippingDiscPercent?props.dataEdit.shippingDiscPercent:0}%
                </Media>
              </Media>
            </div>
            <div className="mb-3">
              <Media>
                <Media body>
                  Created At:
                </Media>
                <Media body className="text-right">
                  {moment(props.dataEdit.createdAt).format("MMM Do YYYY")}
                </Media>
              </Media>
              <Media>
                <Media body>
                  Updated At:
                </Media>
                <Media body className="text-right">
                  {moment(props.dataEdit.updatedAt).format("MMM Do YYYY")}
                </Media>
              </Media>
            </div>
            <Card>
              <CardHeader className="bg-transparent border-0">
                <h4 className="mb-0">Products tables</h4>
              </CardHeader>
              <Table responsive className="align-items-center table-flush">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Variations</th>
                    <th>Qty.</th>
                    <th>Per unit Amt.</th>
                    <th>Total Amt.</th>
                  </tr>
                </thead>
                <tbody>
                {props.dataEdit.purchaseItems?
                  props.dataEdit.purchaseItems.length > 0?
                    props.dataEdit.purchaseItems.map((v, i) => {
                      return (
                        <tr key={i} className="text-uppercase">
                          <th scope="row">{v.name}</th>
                          <td>{v.productCode}</td>
                          <td>
                            {v.variations?
                              v.variations.length > 0? (
                                <ul className="mb-2 text-capitalize">
                                  {v.variations.map((v1, i1) => {
                                    return (
                                      <li key={i1}>- {v1.value} (
                                        <p className="cart-price mb-0 d-inline-block">
                                          <span className="money">
                                          {
                                            props.dataEdit.currency === "USD"? 
                                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.totalAmount)
                                              :
                                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.totalAmount))
                                          }
                                          </span>
                                        </p>
                                      )</li>
                                    )
                                  })}
                                </ul>
                              )
                              :null
                            :null}
                          </td>
                          <td>{v.quantity}</td>
                          <td>
                            {props.dataEdit.currency === "USD"?
                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.perUnitAmount)
                              :
                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.perUnitAmount)
                            }
                          </td>
                          <td>
                            {props.dataEdit.currency === "USD"?
                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.totalNetAmount)
                              :
                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.totalNetAmount)
                            }
                          </td>
                        </tr>
                      )
                    })
                  :(<p>No Product!</p>)
                :null}
                </tbody>
              </Table>
            </Card>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={props.toggleModal}
            >
              Close
            </Button>
          </div>
      </Modal>
    </>
  );
}
 
export default InvoiceModalView;