import {
  FETCH_CURRENCY,
 } from '../actions/settingActions';

const INITIAL_STATE = {
  currency: "USD"
}

export default function settingReducer(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_CURRENCY:
      return {
        ...state,
        currency:action.data,
      }
    default:
      return state

  }
}