import React from "react";
import sumBy from "lodash/sumBy"
import isEmpty from "lodash/isEmpty"

import {
  Media
} from 'reactstrap';

const Calculate = (props) => {

  // no adding rateUSDtoMYR
  // calculated from checkout(main)
  const countShipping = () => {
    if(props.currency === "USD"){
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.shippingFee)
    }else{
      return new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((props.shippingFee))
    }
  }
  
  return (  
    <>
      <div>
        <div className="border-bottom pb-3">
          <table className="product-table w-100">
            <thead className="product-table__header">
              <tr>
                <th scope="col"><span className="visually-hidden">Image</span></th>
                <th scope="col"><span className="visually-hidden">Name</span></th>
                <th scope="col"><span className="visually-hidden">Variations</span></th>
                <th scope="col"><span className="visually-hidden">Total Price</span></th>
              </tr>
            </thead>
            <tbody>
            {props.carts.length > 0?
              props.carts.map((v, i) => {
                return (
                  <tr className="product" key={i}>
                    <td className="product__image">
                      <div className="product-thumbnail ">
                        <div className="product-thumbnail__wrapper">
                          {v.productImg?(
                            <img className="product-thumbnail__image" src={v.productImg} alt="pracworks" />
                          ):<h4>No Image <br/>Preview</h4>}
                          {/* <img alt="pracworks carbon" className="product-thumbnail__image" src="//cdn.shopify.com/s/files/1/3012/8606/products/2-600x600_83ea478c-bb3f-4036-a783-7b481e2f1315_small.jpg?v=1569124598" /> */}
                        </div>
                        <span className="product-thumbnail__quantity" aria-hidden="true">{v.quantity}</span>
                      </div>
                    </td>
                    <th className="product__description" scope="row">
                      <span className="product__description__name order-summary__emphasis">{v.name}</span>
                      {/* <span className="product__description__variant order-summary__small-text">Original</span> */}
                    </th>
                    <td className="text-left">
                      {v.variations?
                        v.variations.length > 0? (
                          <ul className="mb-2 text-capitalize">
                            {v.variations.map((v1, i1) => {
                              return (
                                <li key={i1}>- {v1.value} (
                                  <p className="cart-price mb-0 d-inline-block">
                                    <span className="money">
                                    +{
                                      props.currency === "USD"? 
                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.totalAmount)
                                        :
                                        new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.totalAmount*props.rateUSDtoMYR)
                                      )
                                    }
                                    </span>
                                  </p>
                                )</li>
                              )
                            })}
                          </ul>
                        )
                        :null
                      :null}
                    </td>
                    <td className="product__price text-right">
                      <span className="order-summary__emphasis skeleton-while-loading">
                        {props.currency === "USD"? 
                          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                            v.totalNetAmount+(!isEmpty(v.variations)?sumBy(v.variations, "totalAmount"):0)
                          )
                          :
                          new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                            ((v.totalNetAmount+(!isEmpty(v.variations)?sumBy(v.variations, "totalAmount"):0))*props.rateUSDtoMYR)
                          )
                        }
                      </span>
                    </td>
                  </tr>
                  )
                })
              : <h3 className="text-center border-bottom pt-2 pb-4">Empty Cart</h3>
              }
            </tbody>
          </table>
        </div>
        <div className="my-3">
          <table className="w-100">
            <thead>
              {/* <tr>
                <th scope="col"><span className="visually-hidden">Description</span></th>
                <th scope="col"><span className="visually-hidden">Price</span></th>
              </tr> */}
            </thead>
            <tbody>
              <tr>
                <th scope="row">Subtotal</th>
                <td className="text-right">
                  <span>
                    {props.currency === "USD"? 
                      new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                        sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount")))
                      )
                      :
                      new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                        ((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount"))))*props.rateUSDtoMYR)
                      )
                    }
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <span>
                    Shipping
                  </span>
                </th>
                <td className="text-right">
                  {props.shippingFee?
                    countShipping()
                    : (<span>Fill in address to check shipping cost</span>)}
                </td>
              </tr>
              {/* <tr>
                <th scope="row">Taxes (estimated)</th>
                <td className="text-right">
                  <span>$0.00</span>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="border-top py-3">
          <Media>
            <Media body>
              <p>Total</p>
            </Media>
            <Media body>
              <div className="text-right">
                <span className="d-inline-block pr-2">{props.currency}</span>
                <h3 className="d-inline-block">
                  {props.currency === "USD"? 
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                      (
                        sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount")))
                      )+props.shippingFee
                    )
                    :
                    new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                      (
                        ((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount"))))*props.rateUSDtoMYR)
                        +props.shippingFee
                      )
                    )
                  }
                </h3>
              </div>
            </Media>
          </Media>
        </div>
      </div>
    </>
  );
}
 
export default Calculate;