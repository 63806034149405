import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import Alerts from "../Extra/Alerts.js";

import client from "../../feathers.js"

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  gender: Yup.string()
    .required('Gender is required'),
  birth: Yup.string()
    .required('Birth is required'),
  address: Yup.string()
    .required('Address is required'),
  state: Yup.string()
    .required('State is required'),
  country: Yup.string()
    .required('Country is required'),
  postalCode: Yup.string()
    .required('PostalCode is required'),
  phoneNumber: Yup.string()
    .required('Contact No. is required'),
})

const ProfileForm = (props) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('danger')

  const selectCountry = (val,  setFieldValue) => {
    let code = CountryRegionData.find(e => e[0] === val)[1]
    setFieldValue("country", val)
    setFieldValue("countryCode", code)
    setFieldValue("state", '')
    setFieldValue("stateOrProvinceCode", '')
  }

  const selectState = (val, country, setFieldValue) => {
    let regionData = (CountryRegionData.find(e => e[0] === country)[2]).split("|")
    let stateOrProvinceCode = regionData.find(e => e.split('~')[0] === val)
    setFieldValue("state", val)
    setFieldValue("stateOrProvinceCode", stateOrProvinceCode.split('~')[1])
  }

  const onSubmit = async(value) => {
    if(props.userInfo._id){
      client.authenticate()
      .then(()=>{
        return client.service('users').patch(props.userInfo._id, value)
      })
      .then((res) => {
        setIsOpen(true)
        setColor("success")
        setMessage("Updated Successfully!")
        window.scrollTo(0, 0)
        props.updateDetails(res)
      })
      .catch((err)=>{
        setIsOpen(true)
        setMessage(err.message)
      })
    }
  }
  
  const handleOpen = (bool) => {
    setIsOpen(bool)
  }

  return (  
    <>
      <div className="shadow card">
        <div className="border-0 card-header">
          <div className="align-items-center row">
            <div className="col-8">
              <h3 className="mb-0">My account</h3>
            </div>
            {/* <div className="text-right col-4"><a href="#pablo" className="btn btn-primary btn-sm">Settings</a></div> */}
          </div>
        </div>
        <div className="card-body">
          <Alerts isOpen={isOpen} handleOpen={handleOpen} color={color} message={message} />
          <Formik
            initialValues={{
              firstName: props.userInfo.firstName?props.userInfo.firstName:"",
              lastName: props.userInfo.lastName?props.userInfo.lastName:"",
              gender: props.userInfo.gender?props.userInfo.gender:"",
              birth: props.userInfo.birth?props.userInfo.birth:"",
              address: props.userInfo.address?props.userInfo.address:"",
              country: props.userInfo.country?props.userInfo.country:"",
              countryCode: props.userInfo.countryCode?props.userInfo.countryCode:"",
              stateOrProvinceCode: props.userInfo.stateOrProvinceCode?props.userInfo.stateOrProvinceCode:"",
              state: props.userInfo.state?props.userInfo.state:"",
              postalCode: props.userInfo.postalCode?props.userInfo.postalCode:"",
              phoneNumber: props.userInfo.phoneNumber?props.userInfo.phoneNumber:"",
              description: props.userInfo.description?props.userInfo.description:"",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            render={({ values, errors, setFieldValue, touched }) => (
              <Form>
                <h6 className="heading-small text-muted my-4">User information</h6>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="input-username">Username</label>
                      <input 
                        placeholder="Username" 
                        disabled 
                        type="text" 
                        className="form-control-alternative form-control" 
                        defaultValue={props.userInfo.username?props.userInfo.username:""} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="input-email">Email address</label>
                      <input 
                        placeholder="jesse@example.com" 
                        disabled 
                        type="email" 
                        className="form-control-alternative form-control" 
                        defaultValue={props.userInfo.email?props.userInfo.email:""} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="firstName">First Name</label>
                      <Field 
                        type="text" 
                        name="firstName" 
                        placeholder="FirstName" 
                        className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}  
                        />
                      <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="lastName">Last Name</label>
                      <Field 
                        type="text" 
                        name="lastName" 
                        placeholder="LastName" 
                        className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}  
                        />
                      <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="gender">Gender</label>
                      <Field 
                        as="select"
                        name="gender" 
                        placeholder="Gender" 
                        className={'form-control' + (errors.gender && touched.gender ? ' is-invalid' : '')}  
                      >
                        <option value="male">male</option>
                        <option value="female">female</option>
                        <option value="other">other</option>
                      </Field>
                      <ErrorMessage name="gender" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="birth">Birth</label>
                      <Field 
                        type="date" 
                        name="birth" 
                        placeholder="Birth" 
                        className={'form-control' + (errors.birth && touched.birth ? ' is-invalid' : '')}  
                        />
                      <ErrorMessage name="birth" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Contact information</h6>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="address">Address</label>
                      <Field 
                        type="text" 
                        name="address" 
                        placeholder="Address" 
                        className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}  
                        />
                      <ErrorMessage name="address" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="country">Country</label>
                      <CountryDropdown
                        value={values.country}
                        name="country" 
                        classes={'form-control' + (errors.country && touched.country ? ' is-invalid' : '')}  
                        onChange={(val) => selectCountry(val, setFieldValue)} 
                        />
                      <ErrorMessage name="country" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="state">State</label>
                      <RegionDropdown
                        country={values.country}
                        value={values.state}
                        classes={'form-control' + (errors.state && touched.state ? ' is-invalid' : '')}  
                        onChange={(val) => selectState(val, values.country, setFieldValue)} />
                      <ErrorMessage name="state" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="postalCode">Postal code</label>
                      <Field 
                        type="text" 
                        name="postalCode" 
                        placeholder="PostalCode" 
                        className={'form-control' + (errors.postalCode && touched.postalCode ? ' is-invalid' : '')}  
                        />
                      <ErrorMessage name="postalCode" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-control-label" htmlFor="phoneNumber">Contact No.</label>
                  <Field 
                    type="text" 
                    name="phoneNumber" 
                    placeholder="Contact No." 
                    className={'form-control' + (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')}  
                    />
                  <ErrorMessage name="phoneNumber" component="div" className="invalid-feedback" />
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">About me</h6>
                <div className="form-group">
                  <label>About Me</label>
                  <Field 
                    as="textarea" 
                    name="description" 
                    placeholder="A few words about you ..." 
                    rows={4} 
                    className="form-control-alternative form-control"
                    />
                </div>
                <div className="text-center">
                  <input type="submit" value="Save Changes" className="btn btn-primary" />
                </div>
                </Form>
              )}
            />
        </div>
      </div>
    </>
  );
}
 
export default ProfileForm;