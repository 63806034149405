import React, { useState } from "react";
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { loginDetails, rememberMe } from '../../redux/actions/roleActions';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Alerts from "./Alerts.js";

import client from "../../feathers.js"

import PracWorksLogo from '../../assets/images/17884401_130907670782354_5641047300408986097_n_footer.png'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required')
})

const ModalSignIn = (props) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('danger')

  const onSubmit = (value) => {
    localStorage.clear()
    client.authenticate({
      strategy: 'local', 
      email: value.email, 
      password: value.password
    })
    .then((res)=>{
      handleOpen(true, "success", "Huya! You're ready to go!")
      props.rememberMe(value.email, value.rememberMe)
      props.loginDetails(res)
      setTimeout(() => {
        props.history.push('/')
        setIsOpen(false)
        props.toggle()
      }, 
        500
      );
    })
    .catch((err)=>{
      handleOpen(true, "danger", err.message)
    })
  };

  const handleOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }

  return (
    <div>
      <Modal isOpen={props.visible} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Sign In</ModalHeader>
        <ModalBody>
          <div className="well">
            {/* <h2>Returning Customer</h2>
            <p><strong>I am a returning customer</strong></p> */}
            <div style={{textAlign:'center'}} className="pb-3">
              <img 
                src={PracWorksLogo} 
                style={{width: 192}}
                alt="pracWorks Carbon" 
                />
            </div>
            <Alerts isOpen={isOpen} handleOpen={handleOpen} color={color} message={message} />
            <Formik
              initialValues={{
                  email: '',
                  password: '',
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              render={({ errors, status, touched }) => (
                <Form className="pt-3">
                  <div className="form-group">
                    <Field 
                      type="email" 
                      name="email" 
                      placeholder="Email" 
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}  
                      />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <Field 
                      type="password" 
                      name="password" 
                      placeholder="Password" 
                      className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}  
                      />
                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                  </div>
                  <div style={{textAlign:'center'}}>
                    <p 
                      style={{color: '#007bff', textDecoration: 'underline', cursor:'pointer'}}
                      onClick={props.toggleSwitch}>
                      Don't have an account yet?</p>
                    <input type="submit" value="Sign In" className="btn btn-primary" />
                  </div>
                </Form>
              )}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  rmbEmail: state.role.rmbEmail,
  rmbCbox: state.role.rmbCbox
});

const mapDispatchToProps = {
  loginDetails: loginDetails,
  rememberMe: rememberMe,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalSignIn));