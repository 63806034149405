//Action Types
export const FETCH_CARTS = 'FETCH_CARTS';
export const PUSH_CARTS = 'PUSH_CARTS';
export const UPDATE_CARTS = 'UPDATE_CARTS';
export const REMOVE_CARTS = 'REMOVE_CARTS';
export const CLEAR_CARTS = 'CLEAR_CARTS';

//Action Creator
export const fetchCarts = (data) => ({
   type: FETCH_CARTS,
   data: data
});

export const pushCarts = (data, quantity) => ({
    type: PUSH_CARTS,
    data: data,
    quantity: quantity
 });

export const updateCarts = (data, index, quantity, symbol) => ({
   type: UPDATE_CARTS,
   data: data,
   index: index,
   quantity: quantity,
   symbol: symbol
});

export const removeCarts = (index) => ({
    type: REMOVE_CARTS,
    index: index
});

export const clearCarts = () => ({
    type: CLEAR_CARTS,
});