import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store, persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';

// import './assets/css/index1.css';
import './assets/css/layerednavigation.css';
import './assets/css/custom.css';
import './assets/css/slideshow.css';
import './assets/css/spr.css';
import './assets/css/custommenu.css';
import './assets/css/style.css';
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import './assets/css/index.css';

import Index from './views/Index.js';
import Profile from './views/Profile.js';
import ViewCart from './views/ViewCart.js';
import Checkout from './views/Checkout.js';
import Products from './views/Products.js';
import ProductDesc from './views/ProductDesc.js';
import Summary from './views/Summary.js';
import Policy from './views/Policy.js';
import Terms from './views/Terms.js';

ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Switch>
            <Route path="/" exact render={props => <Index {...props} />} />
            <Route path="/profile/:username" exact render={props => <Profile {...props} />} />
            <Route path="/viewcart" exact render={props => <ViewCart {...props} />} />
            <Route path="/checkout" exact render={props => <Checkout {...props} />} />
            <Route path="/products" exact render={props => <Products {...props} />} />
            <Route path="/productsDescription/:id" exact render={props => <ProductDesc {...props} />} />
            <Route path="/summary/:id" exact render={props => <Summary {...props} />} />
            <Route path="/policy" exact render={props => <Policy {...props} />} />
            <Route path="/terms" exact render={props => <Terms {...props} />} />
            <Redirect to="/" />
          </Switch>
        </PersistGate>
      </Provider>
    </BrowserRouter>,
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
