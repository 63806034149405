//Action Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const REMEMBER_ME = 'REMEMBER_ME';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

//Action Creator
export const loginDetails = (data) => ({
   type: LOGIN_SUCCESS,
   data: data
});

export const updateDetails = (data) => ({
   type: UPDATE_SUCCESS,
   data: data
});

export const rememberMe = (email, bool) => ({
   type: REMEMBER_ME,
   email,
   bool,
});

export const logout = () => ({
   type: LOGOUT_SUCCESS,
});