import React from "react";

import Banner1 from "../../assets/images/banner/26910830_241433526396434_727528337417599327_o.png"
import Banner2 from "../../assets/images/banner/19679487_173311929875261_6375181824724424521_o.png"
import Banner3 from "../../assets/images/banner/IMG-0440.png"
import Banner4 from "../../assets/images/banner/22195408_203712626835191_5474277315401064578_n.png"

const Banner = () => {
  return (  
    <>
      <div>
        <div className="flexslider home-banner">
          <ul className="slides">
            <li>
              <img src={Banner1} alt="pracworks carbon" />
            </li>
            <li>
              <img src={Banner2} alt="pracworks carbon" />
            </li>
            <li>
              <img src={Banner3} alt="pracworks carbon" />
            </li>
            <li>
              <img src={Banner4} alt="pracworks carbon" />
            </li>
          </ul>
          <div className="banne-content-wrap banner7-content">            
            {/* <h1 className="title1">Typi non habent claritatem insitam</h1> */}
            <h2 className="sub-title">PRACWORKS CARBON</h2>
            <div className="banner7-des d-none d-md-block">
              <p>Manufacturer of High Quality Carbon Fiber Intake Manifold and Automotive Parts Since 2013</p>
            </div>
            <div className="banner7-readmore">
              <a href="/products">
                <button className="button">
                  <span>Shop Now</span>
                </button>
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}
 
export default Banner;