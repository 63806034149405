import React, { useState } from "react";
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { fetchCompany } from '../../redux/actions/companyActions';
import { fetchCurrency } from '../../redux/actions/settingActions';
import { removeCarts } from '../../redux/actions/cartsActions';
import { logout } from '../../redux/actions/roleActions';
import classNames from 'classnames';
import sumBy from "lodash/sumBy"
import isEmpty from "lodash/isEmpty"

import ModalSignIn from "../Extra/ModalSignIn.js"
import ModalSignUp from "../Extra/ModalSignUp.js"

import PracWorksLogo from '../../assets/images/navbarLogo.png'
import { Media } from "reactstrap";

import client from "../../feathers.js"

const NavBar = (props) => {
  const [ cartOpen, setCartOpen ] = useState(false)
  const [ currencyOpen, setCurrencyOpen ] = useState(false)
  const [ profileOpen, setProfileOpen ] = useState(false)
  const [ visibleSignIn, setVisibleSignIn ] = useState(false)
  const [ visibleSignUp, setVisibleSignUp ] = useState(false)

  const toggleSignIn = () => {
    setVisibleSignIn(!visibleSignIn)
  }

  const toggleSignUp = () => {
    setVisibleSignUp(!visibleSignUp)
  }

  const toggleSwitch = () => {
    setVisibleSignIn(!visibleSignIn)
    setVisibleSignUp(!visibleSignUp)
  }

  const currencyOnChange = (currency) => {
    props.fetchCurrency(currency)
    setCurrencyOpen(!currencyOpen); setCartOpen(false); setProfileOpen(false)

    if(currency === "MYR"){
      client.service('companies').find({
        query: {
          $limit: 1,
        }
      })
      .then((res)=>{
        props.fetchCompany(res.data[0])
      })
    }
  }

  const logout = () => {
    client.authenticate()
    .then((a) => {
      client.logout()
      props.logout()
      props.history.push('/')
    })
    .catch((err) => {
      props.logout()
      props.history.push('/')
    })
  }

  return (  
    <> 
      <header className="hd3 main-menu">
        <div className="header-inner shadow">
          <div className="upper-header-top">
            <div className="container border-bottom">
              <Media>
                <Media body className="text-left align-self-center">
                  <strong>Phone</strong> :+(60) 16-395 0893
                </Media>
                <Media body className="text-right">
                  <div className="d-flex float-right">
                    <div className={classNames("btn-group", {
                      open: currencyOpen
                    })}>
                      <button 
                        type="button" 
                        data-toggle="dropdown" 
                        data-loading-text="Loading..." 
                        className="btn dropdown-toggle" 
                        aria-expanded="false"
                        style={{fontSize: 14}}
                        onClick={() => {setCurrencyOpen(!currencyOpen); setCartOpen(false); setProfileOpen(false)}}
                      >
                        {props.currency}
                      </button>
                      <div className="dropdown-menu pull-right" style={{width:'initial'}}>
                        <p 
                          className={props.currency === "USD"? "font-weight-bold": ""}
                          style={{cursor:"pointer"}} 
                          onClick={() => currencyOnChange("USD")}>USD</p>
                        <p 
                          className={props.currency === "MYR"? "font-weight-bold": ""}
                          style={{cursor:"pointer"}} 
                          onClick={() => currencyOnChange("MYR")}>MYR</p>
                      </div>
                    </div>
                    <ul className="link-follow">
                      <li>
                        <a className="fa fa-whatsapp" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=60163950893">
                        </a>
                      </li>
                      <li className="first">
                        <a className="fa fa-facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/PracWorksCarbon/">
                        </a>
                      </li>
                      <li>
                        <a className="fa fa-instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/pracworks_carbon/">
                        </a>
                      </li>
                    </ul>
                  </div>
                </Media>
              </Media>
            </div>
          </div>
          <div className="top-menu fix">
            <div className="container">
              <div className="container-inner">
                <div className="hozmenu-container">
                  <div className="nav-container visible-lg visible-md">
                    <div className="box box-left">
                      <a href="/" style={{cursor:'pointer'}}>
                        <img 
                          src={PracWorksLogo} 
                          style={{width: 192}}
                          alt="pracWorks Carbon" 
                        />
                      </a>
                    </div>
                    <div role="menu" id="pt_custommenu" className="pt_custommenu">
                      <div className="pt_menu">
                        <div className="parentMenu">
                          <a href="/">
                            <span>Home</span>
                          </a>
                        </div>
                      </div>
                      {/* <div className="pt_menu nav-2 pt_menu_had_child">
                        <div className="parentMenu">
                          <a href="/search">
                            <span>Layouts</span>
                          </a>
                        </div>
                        <div className="popup" style={{width: '460px', top: '70px', left: '216.109px'}}>
                          <div className="inner-popup">
                            <div className="block1" id="block2" style={{width: '460px'}}>
                              <div className="column  first  col1">
                                <div className="itemMenu level1">
                                  <a className="itemMenuName level1 actParent" href="/#">
                                    <span>Collection Layouts</span>
                                  </a>
                                  <div className="itemSubMenu level0">
                                    <div className="itemMenu level0">
                                      <a className="itemMenuName level0 act" href="/collections"><span>Lookbook</span></a>
                                      <a className="itemMenuName level0 act" href="/collections/all/?preview_theme_id="><span>Left Sidebar</span></a>
                                      <a className="itemMenuName level0 act" href="/collections/all/?preview_theme_id=11105239076"><span>Right Sidebar</span></a>
                                      <a className="itemMenuName level0 act" href="/collections/all/?preview_theme_id=10794008612"><span>No Sidebar</span></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="column  col2">
                                <div className="itemMenu level1">
                                  <a className="itemMenuName level1 actParent" href="/#">
                                    <span>Product Layouts</span>
                                  </a>
                                  <div className="itemSubMenu level0">
                                    <div className="itemMenu level0">
                                      <a className="itemMenuName level0 act" href="/products/aopo-designs-woolrich-klettersack/?preview_theme_id="><span>Horizontal Thumbs</span></a>
                                      <a className="itemMenuName level0 act" href="/products/aopo-designs-woolrich-klettersack/?preview_theme_id=10794008612"><span>Vertical Thumbs</span></a>
                                      <a className="itemMenuName level0 act" href="/products/aopo-designs-woolrich-klettersack/?preview_theme_id=11105239076"><span>Image Rollover</span></a>
                                      <a className="itemMenuName level0 act" href="/products/bopo-designs-woolrich-klettersack/?preview_theme_id="><span>Video Thumb</span></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="clearBoth" />
                            </div>
                          </div>
                        </div>
                      </div> */}
                       {/* <div className="pt_menu nav-5 pt_menu_had_child">
                        <div className="parentMenu">
                          <a href="/products">
                            <span>Products</span>
                          </a>
                        </div>
                        <div className="popup" style={{width: '230px', top: '70px', left: '82.109px'}}>
                          <div className="inner-popup">
                            <div className="block1" style={{width: '230px'}}>
                              <div className="column last col1">
                                <div className="itemMenu level1">
                                  <a className="itemMenuName level1 act nochild" href="/products"><span>All Products</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/products"><span>Honda K-series Intake manifold</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/products"><span>Honda B-series Intake manifold</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/products"><span>Honda F-series Intake manifold</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/products"><span>Accessory</span></a>
                                </div>
                              </div>
                              <div className="clearBoth" />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="pt_menu">
                        <div className="parentMenu">
                          <a href="/products">
                            <span>Products</span>
                          </a>
                        </div>
                      </div>
                      <div className="pt_menu">
                        <div className="parentMenu">
                          <a href="/#about">
                            <span>About Us</span>
                          </a>
                        </div>
                      </div>
                      {/* <div className="pt_menu">
                        <div className="parentMenu">
                          <a href="/#blogs">
                            <span>Blogs</span>
                          </a>
                        </div>
                      </div> */}
                      <div className="pt_menu">
                        <div className="parentMenu">
                          <a href="/#contact">
                            <span>Contact Us</span>
                          </a>
                        </div>
                      </div>
                      {/* <div className="pt_menu nav-5 pt_menu_had_child">
                        <div className="parentMenu">
                          <a href="/pages/contact-us">
                            <span>Pages</span>
                          </a>
                        </div>
                        <div className="popup" style={{width: '230px', top: '70px', left: '435.203px'}}>
                          <div className="inner-popup">
                            <div className="block1" style={{width: '230px'}}>
                              <div className="column last col1">
                                <div className="itemMenu level1">
                                  <a className="itemMenuName level1 act nochild" href="/pages/contact-us"><span>Contact Us</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/blogs/news"><span>Blog</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/cart"><span>Cart</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/checkout"><span>Checkout</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/pages/wishlist"><span>Wishlist</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/account/login"><span>Login</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/account/register"><span>Register</span></a>
                                  <a className="itemMenuName level1 act nochild" href="/404/"><span>404</span></a>
                                </div>
                              </div>
                              <div className="clearBoth" />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="box box-right">
                      {props.username? (
                        <div className={classNames("btn-group", {
                          open: profileOpen
                        })}>
                          <button 
                            type="button" 
                            data-toggle="dropdown" 
                            data-loading-text="Loading..." 
                            className="btn dropdown-toggle" 
                            aria-expanded="false"
                            style={{fontSize: 14}}
                            onClick={() => {setProfileOpen(!profileOpen); setCurrencyOpen(false); setCartOpen(false)}}
                          >
                            {props.username}
                          </button>
                          <div className="dropdown-menu pull-right" style={{width:'initial'}}>
                            <a href={"/profile/"+props.username} style={{color:"#212529"}}>
                              <div className="d-flex">
                                <i className="fa fa-user pr-2" style={{paddingTop: 2}}></i>
                                <p 
                                  className="font-weight-bold"
                                  style={{cursor:"pointer"}}>My Profile</p>
                              </div>
                            </a>
                            <div className="d-flex">
                              <i className="fa fa-sign-out pr-2" style={{paddingTop: 2}}></i>
                              <p 
                                className="font-weight-bold"
                                style={{cursor:"pointer"}} 
                                onClick={logout}>Logout</p>
                            </div>
                          </div>
                        </div>
                      ): (
                        null
                        // <>
                        //   <button 
                        //     className="button" 
                        //     style={{display:'inline-block', margin: '0px 2px', padding: '0px 20px'}}
                        //     onClick={toggleSignIn}
                        //   >
                        //     <span>Sign In</span>
                        //   </button>
                        //   <button 
                        //     className="button"
                        //     style={{margin: '0px 2px', padding: '0px 20px'}}
                        //     onClick={toggleSignUp}
                        //   >
                        //     <span>Sign Up</span>
                        //   </button>
                        // </>
                      )}

                      <div id="cart" className={classNames("btn-group btn-block", {
                        open: cartOpen
                      })}>
                        <button 
                          type="button" 
                          data-toggle="dropdown" 
                          data-loading-text="Loading..." 
                          className="btn dropdown-toggle" 
                          aria-expanded="false"
                          onClick={() => {setCartOpen(!cartOpen); setCurrencyOpen(false); setProfileOpen(false)}}
                        >
                          {/* <i className="ion-bag" />  */}
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                          <span id="cart-total">{props.carts.length}</span>
                        </button>
                          <ul className="dropdown-menu pull-right">
                            {props.carts.length > 0? (
                              <>
                                <li className="has-scroll">
                                  <table className="table">
                                    <tbody>
                                      {props.carts.map((v, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="text-center">
                                              <a href={"/productsDescription/"+v.productId}>
                                                {v.productImg?(
                                                  <img style={{width: 100}} className="cart-image" src={v.productImg} alt="pracworks" />
                                                ):<h3 style={{width: 100}}>No Image <br/>Preview</h3>}
                                              </a>
                                            </td>
                                            <td className="text-left info-item">
                                              <a href={"/productsDescription/"+v.productId}>
                                                {v.name}
                                              </a>
                                              <p className="cart-quantity mb-0">×{v.quantity}</p>
                                              {v.variations?
                                                v.variations.length > 0? (
                                                  <ul className="mb-2 text-capitalize">
                                                    {v.variations.map((v1, i1) => {
                                                      return (
                                                        <li key={i1}>- {v1.value} (
                                                          <p className="cart-price mb-0 d-inline-block">
                                                            <span className="money">
                                                            {
                                                              props.currency === "USD"? 
                                                                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.totalAmount)
                                                                :
                                                                new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.totalAmount*props.rateUSDtoMYR))
                                                            }
                                                            </span>
                                                          </p>
                                                        )</li>
                                                      )
                                                    })}
                                                  </ul>
                                                )
                                                :null
                                              :null}
                                              <p className="cart-price">
                                                <span className="money">
                                                  {props.currency === "USD"? 
                                                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                                      v.totalNetAmount+(!isEmpty(v.variations)?sumBy(v.variations, "totalAmount"):0)
                                                    )
                                                    :
                                                    new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                                                      ((v.totalNetAmount+(!isEmpty(v.variations)?sumBy(v.variations, "totalAmount"):0))*props.rateUSDtoMYR)
                                                    )
                                                  }
                                                </span>
                                              </p>
                                            </td>
                                            <td className="text-center cart-close">
                                              <button 
                                                type="button" 
                                                className="btn btn-danger btn-xs"
                                                onClick={() => props.removeCarts(i)}
                                              >
                                                <i className="fa fa-close" />
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                              </li>
                              <li>
                              <table className="table">
                                <tbody><tr>
                                    <td className="text-left"><strong>Subtotal :</strong></td>
                                    <td className="text-right">
                                      <span className="money">
                                        {props.currency === "USD"? 
                                          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                            sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount")))
                                          )
                                          :
                                          new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                                            ((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount"))))*props.rateUSDtoMYR)
                                          )
                                        }
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p className="text-center cart-button">
                                <a href="/viewcart">View Cart</a> 
                                <a href="/checkout">Checkout</a>
                              </p>
                            </li>
                          </>
                          )
                          : <h3 className="text-center border-bottom pt-2 pb-4">Empty Cart</h3>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {cartOpen || currencyOpen || profileOpen? (
        <div className="menu-overlay" onClick={() => {setProfileOpen(false); setCartOpen(false); setCurrencyOpen(false)}}></div>
      ):null}
      <ModalSignIn 
        visible={visibleSignIn}
        toggle={toggleSignIn}
        toggleSwitch={toggleSwitch}
      />
      <ModalSignUp 
        visible={visibleSignUp}
        toggle={toggleSignUp}
        toggleSwitch={toggleSwitch}
      />
    </>
  );
}
 

const mapStateToProps = state => ({
  auth: state.role.auth,
  username: state.role.details.user?state.role.details.user.username:'',
  userInfo: state.role.details.user?state.role.details.user:{},
  carts: state.carts.data,
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
  currency: state.setting.currency
});

const mapDispatchToProps = {
  fetchCompany: fetchCompany,
  fetchCurrency: fetchCurrency,
  removeCarts: removeCarts,
  logout: logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));