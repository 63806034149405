import React, { useState } from "react";

import { FormGroup, ListGroup, ListGroupItem, Media } from 'reactstrap';
import FedexLogo from '../../assets/images/fedex-logo.svg'
import UpsLogo from '../../assets/images/ups-logo.svg'
import DhlLogo from '../../assets/images/dhl-express.svg'

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  serviceCode: Yup.string()
    .required('Shipping Method is required'),
})

var upsCodes = require('ups-service-codes');

const RenderShippingLists = ({formValue, currency, shippingFee}) => {
    // no adding rateUSDtoMYR
  // calculated from checkout(main)
  const countShipping = () => {
    if(currency === "USD"){
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(shippingFee)
    }else{
      return new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((shippingFee))
    }
  }

  return (
    <ListGroup>
      <ListGroupItem className="justify-content-between">
        <Media>
          <Media body>
            Shipping Service
          </Media>
          <Media body className="text-right">
            <span className="font-weight-bold text-capitalize">
              {formValue.countryCode === 'MY'?
              "Local Express": 
              
                (
                  <>
                  {
                    formValue.shippingCompany === 'ups'?
                    (
                      <span> <img 
                      style={{width: 30}} 
                      src={UpsLogo} 
                      alt="Pracworks" /> 
                      Ups Express</span>
                    ):null
                  }
                  {
                    formValue.shippingCompany === 'fedex'?
                    (
                      <span> <img 
                      style={{width: 30}} 
                      src={FedexLogo} 
                      alt="Pracworks" /> 
                      FedEx Express</span>
                    ):null
                  }
                  {
                    formValue.shippingCompany === 'dhl'?
                    (
                      <span> <img 
                      style={{width: 30, margin: '0 4px'}} 
                      src={DhlLogo} 
                      alt="Pracworks" /> 
                      DHL Express</span>
                    ):null
                  }
                  </>
                )
              }
            </span>
          </Media>
        </Media>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between">
        <Media>
          <Media body>
            Shipping Amount
          </Media>
          <Media body className="text-right">
            <span className="font-weight-bold">{countShipping()}</span>
          </Media>
        </Media>
      </ListGroupItem>
    </ListGroup>
  )
}

const Shipping = (props) => {
  const [ serviceCode, setServiceCode ] = useState(props.formValue.serviceCode?props.formValue.serviceCode:'')

  const updateServiceCode = (value, setFieldValue) => {
    let code = value.Service?.Code
    let shippingCompany = value.shippingCompany
    let price = value.TotalCharges.MonetaryValue

    if(code === "fedEx-INTERNATIONAL_PRIORITY" || code === "fedEx-INTERNATIONAL_ECONOMY"){
      props.formValue.shippingDiscPercent = value.shippingDiscPercent
      props.formValue.shippingDiscAmount = value.shippingDiscAmount
    }else{
      delete props.formValue.shippingDiscPercent
      delete props.formValue.shippingDiscAmount
    }

    setServiceCode(code)
    setFieldValue("serviceCode", code)
    setFieldValue("shippingCompany", shippingCompany)
    props.formValue.serviceCode = code
    props.formValue.shippingCompany = shippingCompany
    props.setFormValue(props.formValue)
    
    
    props.setShippingFee( Number(Number(price).toFixed(2)) )
  }

  const _renderShippingMethod = (value) => {
    if(value?.shippingCompany === 'fedex'){
      return (
        <span className="d-flex align-items-center">
          <img
            style={{width: 80, padding: '2px 5px', margin: '0 10px'}} 
            src={FedexLogo} 
            alt="Pracworks" />
            {(value.Service.Code).replaceAll('_', ' ')}
        </span>
      )
    }else if(value?.shippingCompany === 'dhl'){
      return (
        <span className="d-flex align-items-center">
          <img
            style={{width: 80, padding: '2px 5px', margin: '0 10px'}} 
            src={DhlLogo} 
            alt="Pracworks" />
            {(value.productName).replaceAll('_', ' ')}
        </span>
      )
    }else if(value?.shippingCompany === 'ups'){
      return (
        <span>
          <img 
            style={{width: 80, padding: '2px 5px', margin: '0 10px'}} 
            src={UpsLogo} 
            alt="Pracworks" />
          {upsCodes[value.Service.Code]}
        </span>
      )
    }
  }

  return (  
    <>
      <ul className="mb-3">
        <li className="d-inline-block"><a href="/viewcart">
            Cart
          </a>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}} onClick={() => props.setActiveIndex(0)}>Shipping Address</span>  
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span className="font-weight-bold">Shipping Amount</span>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}}>Information</span>
          <i className="fa fa-chevron-right mx-2"></i>
        </li>
        <li className="d-inline-block">
          <span style={{color:'#8c8c8c'}}>Payment</span>
        </li>
      </ul>
      <div className="mt-4">
        <h3>Infomation</h3>
        <ListGroup>
          {/* <ListGroupItem className="justify-content-between">
            <Media>
              <Media body>
                Contact
              </Media>
              <Media body className="text-right">
                <span className="font-weight-bold">{props.formValue.phoneNumber}</span>
              </Media>
            </Media>
          </ListGroupItem> */}
          <ListGroupItem className="justify-content-between">
            <Media>
              <Media body>
                Ship To
              </Media>
              <Media body className="text-right">
                <span className="font-weight-bold">{props.formValue.address}</span>
              </Media>
            </Media>
          </ListGroupItem>
        </ListGroup>
      </div>
      <div className="mt-5">
        <h3>Shipping Method</h3>
        {props.formValue.countryCode !== 'MY'? 
          (
            <Formik
              initialValues={{
                serviceCode: props.formValue.serviceCode?props.formValue.serviceCode:'',
                shippingCompany: props.formValue.shippingCompany?props.formValue.shippingCompany:'',
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={props.onSubmitShipping}
              render={({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <FormGroup className="shipping-form-check">
                    {
                      ((props.dhlServices).concat(props.fedexServices).concat(props.upsServices)).map((value, index) => {
                        return (
                          <FormGroup key={index}>
                            <label 
                              style={{padding: '8px 10px'}} 
                              htmlFor="serviceCode"
                              onClick={() => updateServiceCode(value, setFieldValue)}
                            >
                              <input 
                                type="radio" 
                                name="serviceCode"
                                value={values.serviceCode}
                                checked={serviceCode === value.Service?.Code}
                                />
                                <div className="text-capitalize font-weight-bold">
                                  {
                                    _renderShippingMethod(value)
                                  }
                                </div>
                                <h4>
                                  {props.currency === "MYR"? 
                                    new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                                      value.TotalCharges.MonetaryValue
                                    )
                                    :
                                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                      (value.TotalCharges.MonetaryValue)
                                    )
                                  }
                                </h4>
                            </label>
                          </FormGroup>
                        )
                      })
                    }
                    {props.fedExSpin?(
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Loading...
                      </div>
                    ):null}
                    {props.upsSpin?(
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Loading...
                      </div>
                    ):null}
                    {props.dhlSpin?(
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Loading...
                      </div>
                    ):null}
                    <div className="invalid-feedback d-block">{errors.serviceCode}</div>
                  </FormGroup>
                  <RenderShippingLists 
                    formValue={props.formValue}
                    currency={props.currency}
                    shippingFee={props.shippingFee}
                    />
                  <div className="mt-3">
                    <button type="submit" className="button">
                      <span>Proceed To Checkout</span>
                    </button>
                  </div>
                </Form>
              )}
            />
          )
          :(
            <>
              <RenderShippingLists 
                formValue={props.formValue}
                currency={props.currency}
                shippingFee={props.shippingFee}
                />
              <div className="mt-3">
                <button type="submit" className="button" onClick={() => props.onSubmitShipping()}>
                  <span>Proceed To Checkout</span>
                </button>
              </div>
            </>
          )}

      </div>
    </>
  );
}
 
export default Shipping;