import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchProducts, updateProducts } from '../redux/actions/productsActions';
import { pushCarts } from '../redux/actions/cartsActions';
import classnames from "classnames"
import uniqBy from "lodash/uniqBy"

// import { 
//   Media
// } from 'reactstrap';

import NavBar from "../components/NavBar/Main.js"
import Footer from "../components/Footer/Main.js"

import client from "../feathers"

const Products = (props) => {
  const [ grid, setGrid ] = useState('grid')
  

  useEffect(() => {
    async function reFetchData(){
      let reFetch = await fetchData(props.fetchProducts, 0)
      
      if(!reFetch){
        console.log(reFetch)
        setTimeout(async function(){ 
          reFetch = await fetchData(props.fetchProducts, 0)
          if(!reFetch){
            console.log(reFetch)
            setTimeout(async function(){
              reFetch = await fetchData(props.fetchProducts, 0)
              if(!reFetch){ 
                console.log(reFetch)
              setTimeout(async function(){ 
                reFetch = await fetchData(props.fetchProducts, 0)
                if(!reFetch){
                  console.log(reFetch)
                  setTimeout(async function(){ 
                    reFetch = await fetchData(props.fetchProducts, 0)
                    console.log(reFetch)
                  }, 2000);
                }
              }, 2000);
              }
            }, 2000);
          }
        }, 2000);
      }
    }
    reFetchData()
  }, [props.fetchProducts])
  
  const fetchData = (fetchProducts, skip) => {
    return client.service('products').find({
      query: {
        // $skip: skip,
        $limit: 20,
        $sort: {
          dateToFirst: -1
        }
      }
    })
    .then((res) => {
      let result = res.data.map(v => {
        if(v.variations){
          if(v.variations.length > 0){

            let uniqTypes = uniqBy(v.variations, "type")
            v.variations.map(v1 => {
              uniqTypes.map(uniType => {
                if(uniType._id === v1._id){
                  v1.selected = true
                  return v1
                }else{return v1}
              })
              return v1
            })
            return v
            
          }else{return v}
        }else{return v}
      })
      fetchProducts(result)

      if(res.data.length > 0){
        setTimeout(
          () => {
            var $ = window.$
        
            $(document).ready(function(){
              if ($(".add-to-cart-button")[0]){
                document.querySelectorAll('.add-to-cart-button').forEach(function(addToCartButton) {
                  addToCartButton.addEventListener('click', function() {
                    addToCartButton.classList.add('added');
                    setTimeout(function(){
                      addToCartButton.classList.remove('added');
                    }, 2000);
                  });
                });
              }
            });
          }
          ,500
        ); 
      }
      return true
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        console.log(true, 'danger', "Please Sign-in to continue!")
        return true
      }else if(err.name === "Timeout"){
        return false
      }else{
        console.log(true, 'danger', err.message)
        return true
      }
    })
  }

  // const addToCart = (v, quantity) => {
  //   props.pushCarts(v, quantity)
  // }
  
  // const onChangeVariation = (data, type, value) => {
  //   let removeSelected = data.variations.map(v => {
  //     if(v.type === type){
  //       delete v.selected
  //       return v
  //     }else{return v}
  //   })
  //   removeSelected.map(v => {
  //     if(v.type === type){
  //       if(v.value === value){
  //         v.selected = true
  //         return v
  //       }else{ return v }
  //     }else{ return v }
  //   })
  //   data.variations = removeSelected
  //   props.updateProducts(data)
  // }

  return ( 
    <>  
      <NavBar />
      <main id="content">
        {/* <div
            style={{
              backgroundImage: 'url(//cdn.shopify.com/s/files/1/3012/8606/t/4/assets/collection_top.jpg?v=13539636231063041806)', 
              position:'relative', 
              marginBottom: 100,
              minHeight: 530
            }}
          >
          <div className="breadcrumbs">
            <div className="container" style={{paddingRight: 60, paddingLeft: 60}}>
              <h1>Products</h1>
              <ul className="breadcrumb" style={{display: 'block'}}>
                <li><a href="/" style={{color:'#fff'}}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/viewcart"><span>Products</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="custom-container">
            <div className="row">
              {/* <aside id="column-left" className="col-sm-12 col-md-3 hidden-xs">
                <div className="layernavigation-module">
                  <div className="panel panel-default">
                    <div className="layered">
                      <div className="list-group">
                        <div className="filter-attribute-container filter-attribute-remove-container">
                        </div>
                        <div className="filter-attribute-container filter-categories">
                          <label>Categories</label>
                          <div className="list-group-item">
                            <div id="filter-group0">
                              <a className="a-filter add-filter advanced-filter" href="/collections/wheels">Featured (13 items)</a>
                              <a className="a-filter add-filter advanced-filter" href="/collections/sounds">Sounds (7 items)</a>
                              <a className="a-filter add-filter advanced-filter" href="/collections/wheels-1">Wheels (6 items)</a>
                            </div>
                          </div>
                        </div>
                        <div className="filter-attribute-container">
                          <label>color</label>
                          <div className="list-group-item">
                            <div id="filter-group1" className="advanced-filters" data-filter-group="color">
                              <span>
                                <a href="/collections/all/color_black" className="advanced-filter a-filter add-filter">black</a>
                              </span>
                              <span>
                                <a href="/collections/all/color_brown" className="advanced-filter a-filter add-filter">brown</a>
                              </span>
                              <span>
                                <a href="/collections/all/color_grey" className="advanced-filter a-filter add-filter">grey</a>
                              </span>
                              <span>
                                <a href="/collections/all/color_red" className="advanced-filter a-filter add-filter">red</a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="filter-attribute-container">
                          <label>price</label>
                          <div className="list-group-item">
                            <div id="filter-group2" className="advanced-filters" data-filter-group="price">
                              <span>
                                <a href="/collections/all/price_0-100" className="advanced-filter a-filter add-filter">0-100</a>
                              </span>
                              <span>
                                <a href="/collections/all/price_100-150" className="advanced-filter a-filter add-filter">100-150</a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="filter-attribute-container">
                          <label>size</label>
                          <div className="list-group-item">
                            <div id="filter-group3" className="advanced-filters" data-filter-group="size">
                              <span>
                                <a href="/collections/all/size_large" className="advanced-filter a-filter add-filter">large</a>
                              </span>
                              <span>
                                <a href="/collections/all/size_medium" className="advanced-filter a-filter add-filter">medium</a>
                              </span>
                              <span>
                                <a href="/collections/all/size_small" className="advanced-filter a-filter add-filter">small</a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="filter-attribute-container">
                          <label>type</label>
                          <div className="list-group-item">
                            <div id="filter-group4" className="advanced-filters" data-filter-group="type">
                              <span>
                                <a href="/collections/all/type_chinese" className="advanced-filter a-filter add-filter">chinese</a>
                              </span>
                              <span>
                                <a href="/collections/all/type_original" className="advanced-filter a-filter add-filter">original</a>
                              </span>
                              <span>
                                <a href="/collections/all/type_used" className="advanced-filter a-filter add-filter">used</a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-static static-sidebar">
                  <div className="image">
                    <a href="/#">
                      <img src="//cdn.shopify.com/s/files/1/3012/8606/t/4/assets/collection_side.jpg?v=18092128903795707047" alt="#" />
                    </a>
                  </div>
                </div>
              </aside> */}
              {/* <div id="content" className="col-md-9 col-sm-12 col-xs-12"> */}
              <div id="content" className="col-sm-12">
                <div className="category-info" />
                <div className="custom-category">
                  <div id="wraper_ajax" className="loadding_ajaxcart"><i className="fa fa-spinner fa-spin" /></div>
                  <div className="toolbar toolbar-products toolbar-top">
                    <div className="modes">
                      <div className="btn-group">
                        <button 
                          type="button" 
                          id="list-view" 
                          className={classnames("btn btn-default",{
                            selected: grid === 'list'
                          })}
                          onClick={() => setGrid('list')}>
                            <i className="fa fa-th-list" /></button>
                        <button 
                          type="button" 
                          id="grid-view" 
                          className={classnames("btn btn-default",{
                            selected: grid === 'grid'
                          })}
                          onClick={() => setGrid('grid')}><i className="fa fa-th" /></button>
                      </div>
                      {/* Nav tabs */}</div>
                    <div className="toolbar-amount">
                      <span>Showing {props.productsData.length} items
                      </span></div>
                    {/* <div className="sorter"><label>Sort by:</label>
                      <select className="form-control" name="input-sort" id="input-sort">
                        <option value="manual">Featured</option>
                        <option value="best-selling">Best Selling</option>
                        <option value="title-ascending">Alphabetically, A-Z</option>
                        <option value="title-descending">Alphabetically, Z-A</option>
                        <option value="price-ascending">Price, low to high</option>
                        <option value="price-descending">Price, high to low</option>
                        <option value="created-descending">Date, new to old</option>
                        <option value="created-ascending">Date, old to new</option>
                      </select>
                    </div>
                    <div className="limiter"><label>Show:</label>
                      <select id="input-limit" className="form-control">
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value="all">All</option>
                      </select>
                    </div> */}
                  </div>
                  <div className={grid === 'grid'? "custom-products quickview-products custom-products-row": "custom-products quickview-products"} id="custom-products">
                    <div className="row">
                    {props.productsData.length > 0? (
                      props.productsData.map((v, i) => {
                        return (
                          <div key={i} className={grid === 'grid'?"product-layout product-grid grid-style col-md-3 col-sm-6 col-xs-6 three-items" :"product-layout product-list col-xs-12 col-sm-12"}>
                            <div className="product-thumb">
                              <div className="item">
                                <div className="item-inner">
                                  <div className={grid === 'grid'?"col-image" :"col-image col-sm-3 col-sms-4 col-smb-12"}>
                                    <div className="image images-container">
                                      <a href={"/productsDescription/"+v._id}
                                        className="product-image">
                                          {v.productImg.length > 0? (
                                            <>
                                            {/* {v.productImg.length >= 2? (
                                              <>
                                                <img className="img-responsive"
                                                src={v.productImg[0].fileUrl}
                                                alt="pracWorks" />
                                                <img className="img-r"
                                                src={v.productImg[1].fileUrl}
                                                alt="pracWorks" />
                                              </>
                                              ): (
                                                <img className="img-responsive"
                                                src={v.productImg[0].fileUrl}
                                                alt="pracWorks" />
                                              )} */}
                                              <img className="img-responsive"
                                                src={v.productImg[0].fileUrl}
                                                alt="pracWorks" />
                                            </>
                                          ): <i className="fa fa-image" style={{fontSize: '5rem', padding: 20}}></i>}
                                      </a>
                                      {/* <div className="action-links">
                                        <button className="button btn-wishlist" type="button">
                                          <i className="ion-android-favorite-outline" /><span>Add to Wish
                                            List</span>
                                        </button>
                                        <button className="button btn-compare" type="button">
                                          <span>View Details</span>
                                        </button>
                                        <button className="button btn-quickview quickview" type="button">
                                            <span>Quick View</span>
                                        </button>
                                      </div> */}
                                    </div>
                                  </div>
                                  {/* image */}
                                  <div className="col-des">
                                    <div className="caption">
                                      {/* <div className="ratings">
                                        <div className="rating-box">
                                          <span className="spr-badge" id="spr_badge_516676321316" data-rating={5.0}><span className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /></span><span className="spr-badge-caption">1 review</span>
                                          </span>
                                        </div>
                                      </div> */}
                                      <h4 className="product-name">
                                        <a href={"/productsDescription/"+v._id}>
                                          {v.name}
                                        </a>
                                      </h4>
                                      <p className="product-code">
                                        {v.code}
                                      </p>
                                      <div className="product-des" style={{whiteSpace: 'pre-line'}}>
                                        {v.description?v.description:"..."}
                                      </div>
                                      <a href={"/productsDescription/"+v._id}>
                                      <div className="price-box">
                                        {/* <label>Loading...:</label> */}
                                        <p className="special-price">
                                          <span className="price">
                                            <span className="money">
                                              {props.currency === "USD"? 
                                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.netAmount)
                                              :
                                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v.netAmount*props.rateUSDtoMYR))
                                              }
                                            </span>
                                          </span>
                                        </p>
                                        {/* <p className="old-price"><span className="price"><span className="money"
                                              data-currency-usd="$150.00">$150.00</span></span></p> */}
                                      </div>
                                      </a>
                                      {/* <div>
                                        {v.variations?
                                          v.variations.length > 0?
                                            uniqBy(v.variations, "type").map((uniType, uniIndex) => {
                                              return (
                                                <div key={uniIndex} className="mt-2">
                                                  <Media>
                                                    <Media body>
                                                      <h4 className="text-uppercase">{uniType.type.split('_').join(' ')}</h4>
                                                    </Media>
                                                    <Media body className="text-right">
                                                      <div className="price-box mt-0">
                                                        <p className="special-price">
                                                          <span className="price" style={{fontSize:14}}>
                                                            <span className="money">
                                                              {v.variations.map((v1) => {
                                                                if(v1.type === uniType.type){
                                                                  if(v1.selected){
                                                                    return (props.currency === "USD"? 
                                                                      new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.amount)
                                                                      :
                                                                      new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.amount*props.rateUSDtoMYR))
                                                                    )
                                                                  }else{return false}
                                                                }else{return false}
                                                              })}
                                                            </span>
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </Media>
                                                  </Media>
                                                  <select onChange={(e)=>onChangeVariation(v, uniType.type, e.target.value)} className="form-control text-capitalize">
                                                    {v.variations.map((v1, i1) => {
                                                      if(v1.type === uniType.type){
                                                        return (
                                                          <option key={i1} value={v1.value}>{v1.value}
                                                          (+{
                                                            props.currency === "USD"? 
                                                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.amount)
                                                              :
                                                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.amount*props.rateUSDtoMYR))
                                                          })
                                                          </option>
                                                        )
                                                      }else{return false}
                                                    })
                                                    }
                                                  </select>
                                                </div>
                                              )
                                            })
                                          :null
                                        :null}
                                      </div> */}
                                      {/* <button 
                                        className="button btn-cart add-to-cart-button" 
                                        type="button"
                                        onClick={() => addToCart(v, 1)}>
                                          <svg className="tick" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="#ffffff" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"/></svg>
                                          <span className="add-to-cart">Add to cart</span>
                                          <span className="added-to-cart">Added to cart</span>
                                      </button> */}
                                    </div>
                                    {/* caption */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* product-thumb */}
                          </div>
                          )
                        })
                      ): null}
                    </div>
                  </div>
                  {/* <div className="toolbar toolbar-products toolbar-bottom">
                    <div className="toolbar-amount">
                      <span>Showing {props.productsData.length} items</span>
                    </div>
                    <div className="pages">
                      <ul className="pagination">
                        <li className="active"><span>1</span></li>
                        <li>
                          <a href="/collections/all?page=2">2</a>
                        </li>
                        <li className="pagination-next">
                          <a href="/collections/all?page=2">
                            &gt;
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
 

const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  productsData: state.products.data,
  companyInfo: state.company.data,
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
  currency: state.setting.currency
});

const mapDispatchToProps = {
  fetchProducts: fetchProducts,
  updateProducts: updateProducts,
  pushCarts: pushCarts
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);