import React, { useEffect } from "react";
// import uniqBy from "lodash/uniqBy"

// import { 
//   Media
// } from 'reactstrap';

const Products = ({updateProducts, productsData, currency, rateUSDtoMYR, addToCart}) => {

  useEffect(() => {
    if(productsData.length > 0){
      setTimeout(
        () => {
          var $ = window.$
      
          $(document).ready(function(){
            if ($(".owl-carousel")[0]){
              // Do something if class exists
              $('.owl-carousel').owlCarousel({
                items: 4,
                margin: 30,
                nav: true,
                responsiveClass:true,
                responsive:{
                  0:{
                    items:1,
                  },
                  768:{
                    items:4,
                  },
                  // 1024:{
                  //   items:4,
                  // }
                }
                // autoWidth: true,
                // videoWidth: true,
                // info: true,
                // nestedItemSelector: true,
              });
            }
            
            if ($(".add-to-cart-button")[0]){
              document.querySelectorAll('.add-to-cart-button').forEach(function(addToCartButton) {
                addToCartButton.addEventListener('click', function() {
                  addToCartButton.classList.add('added');
                  setTimeout(function(){
                    addToCartButton.classList.remove('added');
                  }, 2000);
                });
              });
            }
          });
        }
        ,500
      ); 
    }
  }, [productsData])

  // const onChangeVariation = (data, type, value) => {
  //   let removeSelected = data.variations.map(v => {
  //     if(v.type === type){
  //       delete v.selected
  //       return v
  //     }else{return v}
  //   })
  //   removeSelected.map(v => {
  //     if(v.type === type){
  //       if(v.value === value){
  //         v.selected = true
  //         return v
  //       }else{ return v }
  //     }else{ return v }
  //   })
  //   data.variations = removeSelected
  //   updateProducts(data)
  // }

  return (  
    <>
      <div className="index-section">
        <div className="main-row ">
          <div className="container">
            <div className="row">
              <div className="main-col col-sm-12 col-md-12">
                <div className="row sub-row">
                  <div className="sub-col col-sm-12 col-md-12">
                    <div className="tt_tabsproduct_module tabs-category-slider tt-product2 "
                      id="product_module1501181471034">
                      <div className="module-title title2">
                        <h2><span>PRODUCTS</span></h2>
                      </div>
                      {/* <div className="module-description">
                        <p>Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                      </div> */}
                      {/* <ul className="tab-heading tabs-categorys">
                        <li className="active">
                          <a data-toggle="pill" href="#tab-15011814710340">Wheels</a>
                        </li>
                        <li>
                          <a data-toggle="pill" href="#tab-15011814710341">Sounds</a>
                        </li>
                        <li>
                          <a data-toggle="pill" href="#tab-15011814710342">Featured</a>
                        </li>
                        <li>
                          <a data-toggle="pill" href="#tab-15011814710343">Home page</a>
                        </li>
                      </ul> */}
                      <div className="tt-product tt-product2">
                        <div className="tab-content">
                        <div className="owl-carousel owl-theme visible-own-buttons">
                          {productsData.length > 0? (
                            productsData.map((v, i) => {
                              return (
                                <div key={i}>
                                  <div className="row_items">
                                    <div className="product-layout grid-style">
                                      <div className="product-thumb transition">
                                        <div className="item">
                                          {/* <div className="item-inner product-col"> */}
                                          <div className="item-inner">
                                            <div className="image images-container">
                                              {/* <div className="label-product label_sale">
                                                <span>-33%</span>
                                              </div> */}
                                              <a href={"/productsDescription/"+v._id}
                                                className="product-image">
                                                  {v.productImg.length > 0? (
                                                    <>
                                                    {/* {v.productImg.length >= 2? (
                                                      <>
                                                        <img className="img-responsive"
                                                        src={v.productImg[0].fileUrl}
                                                        alt="pracWorks" />
                                                        <img className="img-r"
                                                        src={v.productImg[1].fileUrl}
                                                        alt="pracWorks" />
                                                      </>
                                                      ): (
                                                        <img className="img-responsive"
                                                        src={v.productImg[0].fileUrl}
                                                        alt="pracWorks" />
                                                      )} */}
                                                      <img className="img-responsive"
                                                        src={v.productImg[0].fileUrl}
                                                        alt="pracWorks" />
                                                    </>
                                                  ): <i className="fa fa-image" style={{fontSize: '5rem', padding: 20}}></i>}
                                              </a>
                                              {/* <div className="action-links">
                                                <button className="button btn-wishlist" type="button">
                                                  <i className="ion-android-favorite-outline" /><span>Add to Wish
                                                    List</span>
                                                </button>
                                                <button className="button btn-compare" type="button">
                                                  <span>View Details</span>
                                                </button>
                                                <button className="button btn-quickview quickview" type="button">
                                                    <span>Quick View</span>
                                                </button>
                                              </div> */}
                                            </div>
                                            {/* image */}
                                            <div className="caption">
                                              {/* <div className="ratings">
                                                <div className="rating-box">
                                                  <span className="spr-badge" id="spr_badge_516683104292"
                                                    data-rating={0.0}><span
                                                      className="spr-starrating spr-badge-starrating"><i
                                                        className="spr-icon spr-icon-star" /><i
                                                        className="spr-icon spr-icon-star-empty" /><i
                                                        className="spr-icon spr-icon-star-empty" /><i
                                                        className="spr-icon spr-icon-star-empty" /><i
                                                        className="spr-icon spr-icon-star-empty" /></span><span
                                                      className="spr-badge-caption">No reviews</span>
                                                  </span>
                                                </div>
                                              </div> */}
                                              <h4 className="product-name">
                                                <a href={"/productsDescription/"+v._id}>
                                                  {v.name}
                                                </a>
                                              </h4>
                                              {/* <div className="product-des">
                                                {v.description?v.description:"..."}
                                              </div> */}
                                              {/* <p className="rate-special">
                                                -33%
                                              </p> */}
                                              <a href={"/productsDescription/"+v._id}>
                                              <div className="price-box">
                                                {/* <label>Loading...:</label> */}
                                                <p className="special-price">
                                                  <span className="price">
                                                    <span className="money">
                                                      {currency === "USD"? 
                                                      new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.netAmount)
                                                      :
                                                      new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v.netAmount*rateUSDtoMYR))
                                                      }
                                                    </span>
                                                  </span>
                                                </p>
                                                {/* <p className="old-price"><span className="price"><span className="money"
                                                      data-currency-usd="$150.00">$150.00</span></span></p> */}
                                              </div>
                                              </a>
                                              {/* <div>
                                                {v.variations?
                                                  v.variations.length > 0?
                                                    uniqBy(v.variations, "type").map((uniType, uniIndex) => {
                                                      return (
                                                        <div key={uniIndex} className="mt-2">
                                                          <Media>
                                                            <Media body>
                                                              <h4 className="text-uppercase">{uniType.type.split('_').join(' ')}</h4>
                                                            </Media>
                                                            <Media body className="text-right">
                                                              <div className="price-box mt-0">
                                                                <p className="special-price">
                                                                  <span className="price" style={{fontSize:14}}>
                                                                    <span className="money">
                                                                      {v.variations.map((v1) => {
                                                                        if(v1.type === uniType.type){
                                                                          if(v1.selected){
                                                                            return (currency === "USD"? 
                                                                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.amount)
                                                                              :
                                                                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.amount*rateUSDtoMYR))
                                                                            )
                                                                          }else{return false}
                                                                        }else{return false}
                                                                      })}
                                                                    </span>
                                                                  </span>
                                                                </p>
                                                              </div>
                                                            </Media>
                                                          </Media>
                                                          <select onChange={(e)=>onChangeVariation(v, uniType.type, e.target.value)} className="form-control text-capitalize">
                                                            {v.variations.map((v1, i1) => {
                                                              if(v1.type === uniType.type){
                                                                return (
                                                                  <option key={i1} value={v1.value}>{v1.value}
                                                                  (+{
                                                                    currency === "USD"? 
                                                                      new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.amount)
                                                                      :
                                                                      new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.amount*rateUSDtoMYR))
                                                                  })
                                                                  </option>
                                                                )
                                                              }else{return false}
                                                            })
                                                            }
                                                          </select>
                                                        </div>
                                                      )
                                                    })
                                                  :null
                                                :null}
                                              </div> */}
                                              {/* <button 
                                                className="button btn-cart add-to-cart-button" 
                                                type="button"
                                                onClick={() => addToCart(v, 1)}>
                                                  <svg className="tick" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="#ffffff" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"/></svg>
                                                  <span className="add-to-cart">Add to cart</span>
                                                  <span className="added-to-cart">Added to cart</span>
                                              </button> */}
                                            </div>
                                            {/* caption */}
                                          </div>
                                        </div>
                                      </div>
                                      {/* product-thumb */}
                                    </div>
                                    {/* product-layout */}
                                  </div>
                                </div>
                              )
                            })
                          ): null}
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default Products;