import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import client from "../../feathers"
import env from "../../env.js"

import Alerts from "../Extra/Alerts.js";

import PracWorksLogo from '../../assets/images/17884401_130907670782354_5641047300408986097_n_footer.png'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  message: Yup.string()
    .required('Message is required'),
})

const Subscribe  = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('danger')

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${env.GRECAPTCHA_V3_KEY}`
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
  }, [])
  
  const handleLoaded = _ => {
    return new Promise(resolve => {
      window.grecaptcha.ready(_ => {
        window.grecaptcha
          .execute(env.GRECAPTCHA_V3_KEY, { action: "submit" })
          .then(token => {
            return resolve(token)
          })
      })
    })
  }
  
  const onSubmit = async(value, func) => {
    let email = env.mailTo
    let token = await handleLoaded()
    if(token){
      axios.post(`${client.io.io.uri}sendEmail`,{
        params: {...value, mailTo: email},
      })
      .then((res) => {
        handleOpen(true, 'success', "Mail Submit Successfully!")
        func.resetForm({})
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          handleOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          handleOpen(true, 'danger', err.message)
        }
      })
    }
  }
  
  const handleOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }

  return (  
    <>
      <div className="insta-section index-section--flush" id="contact">
        <div className="main-row  ">
          <div className="container">
            <div className="row">
              <div className="main-col col-sm-12 col-md-12">
                <div className="row sub-row">
                  <div className="sub-col col-sm-12 col-md-12">
                    <div id="instagram_block_home" className="instagram-container">
                      <div className="module-title title2">
                        <h2># FOLLOW US ON SOCIAL MEDIA</h2>
                      </div>
                      {/* <div className="module-description">
                        <p>How us how you are styling your Rare pieces on Instagram with the hashtag #PracWorks.</p>
                      </div> */}
                      <div className="content_block" id="instafeed">
                        <div className="row" style={{justifyContent: 'center', marginBottom: 12}}>
                          <div className="col-md-3">
                            <img src={PracWorksLogo} alt="pracworks carbon" />
                          </div>
                        </div>
                        <a className="button" target="_blank" rel="noreferrer" href="https://www.facebook.com/PracWorksCarbon/">
                          <span><i className="fa fa-facebook"></i></span>
                        </a>
                        <a className="button" target="_blank" rel="noreferrer" href="https://www.instagram.com/pracworks_carbon/">
                          <span><i className="fa fa-instagram"></i></span>
                        </a>
                      </div>
                      
                      <div className="main-row f2" style={{margin:'50px 0px'}}>
                        <div className="newletter-subscribe text-center">
                          <div id="boxes-normal" className="newletter-container">
                            <div id="dialog-normal" className="window">
                              <div className="box">
                                <div className="newletter-title">
                                  <div className="static-welcome">
                                    <div className="title">
                                      <h2>Contact Us</h2>
                                    </div>
                                  </div>
                                  {/* <h1>Drop a message to us</h1> */}
                                  <h4>For more information, email or drop us a message and we will get back to you as soon as possible.</h4>
                                </div>
                                <div className="box-content newleter-content">
                                  <div id="frm_subscribe-normal">
                                    <div id="subscribe">
                                      <h4>
                                        <i className="fa fa-envelope-o mr-2"></i>
                                        <span>Email :</span>Inquiries@pracworks.com.my
                                      </h4>
                                      <h4>
                                        <i className="fa fa-phone mr-2"></i>
                                        <span>Phone :</span>+(60) 16-395 0893
                                      </h4>
                                      <h4>
                                        <a href="https://api.whatsapp.com/send?phone=60163950893" target="_blank" rel="noreferrer">
                                        <i className="fa fa-whatsapp mr-2"></i>
                                        <span>WhatsApp :</span>+(60) 16-395 0893
                                        </a>
                                      </h4>
                                      {/* <form 
                                        id="contact_form"
                                        acceptCharset="UTF-8" 
                                        className="contact-form">
                                          <input type="text" 
                                            // defaultValue 
                                            placeholder="Email Address" 
                                            name="contact[email]"
                                            id="subscribe_email" 
                                            required 
                                            aria-label="Email Address" 
                                            autoCorrect="off"
                                            autoCapitalize="off" />
                                        <button className="btn" type="submit">
                                          <span>Subscribe</span>
                                        </button>
                                      </form> */}
                                       <Alerts isOpen={isOpen} handleOpen={handleOpen} color={color} message={message} />
                                      <Formik
                                        initialValues={{
                                          firstName: '',
                                          lastName: '',
                                          email: '',
                                          message: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(value, func) => onSubmit(value, func)}
                                        render={({ errors, status, touched }) => (
                                          <Form className="contact-form pt-3" id="contact_form">
                                            <div className="form-group">
                                              <Field 
                                                id="email"
                                                type="email" 
                                                name="email" 
                                                placeholder="Email" 
                                                className={'form-control subscribe_email' + (errors.email && touched.email ? ' is-invalid' : '')}  
                                                />
                                              <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="form-group">
                                                  <Field 
                                                    id="firstName"
                                                    type="text" 
                                                    name="firstName" 
                                                    placeholder="First Name" 
                                                    className={'form-control subscribe_email w-100' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}  
                                                    />
                                                  <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="form-group">
                                                  <Field 
                                                    id="lastName"
                                                    type="text" 
                                                    name="lastName" 
                                                    placeholder="Last Name" 
                                                    className={'form-control subscribe_email w-100' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}  
                                                    />
                                                  <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group">
                                              <Field 
                                                as="textarea" 
                                                name="message" 
                                                style={{background: '#efefef', border:0}}
                                                placeholder="Message..."
                                                rows={4} 
                                                className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')}  
                                                />
                                              <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                              <button className="button" type="submit">
                                                <span>Submit</span>
                                              </button>
                                            </div>
                                          </Form>
                                        )}
                                      />
                                    </div>
                                  </div>
                                  {/* /#frm_subscribe */}
                                </div>
                                {/* /.box-content */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{/* END content_for_index */}
    </>
  );
}
 
export default Subscribe ;