import roleReducer from './roleReducer';
import productsReducer from './productsReducer';
import companyReducer from './companyReducer';
import settingReducer from './settingReducer';
import cartsReducer from './cartsReducer';

import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    role: roleReducer,
    products: productsReducer,
    company: companyReducer,
    setting: settingReducer,
    carts: cartsReducer,
});

export default rootReducer;