import React from "react";
import { connect } from 'react-redux';
import { updateCarts, removeCarts } from '../redux/actions/cartsActions';
import sumBy from "lodash/sumBy"
import isEmpty from "lodash/isEmpty"

import NavBar from "../components/NavBar/Main.js"
import Footer from "../components/Footer/Main.js"

const ViewCart = (props) => {

  const minusCart = (v, i, quantity, symbol) => {
    if((v.quantity-quantity) < 1){
      props.removeCarts(i)
    }else{
      props.updateCarts(v, i, quantity, symbol)
    }
  }

  return (  
    <>  
      <NavBar />
      <main id="content">
        <div
            style={{
              backgroundImage: 'url(//cdn.shopify.com/s/files/1/3012/8606/t/4/assets/collection_top.jpg?v=13539636231063041806)', 
              position:'relative', 
              marginBottom: 100,
              minHeight: 530
            }}
          >
          <div className="breadcrumbs">
            <div className="container" style={{paddingRight: 60, paddingLeft: 60}}>
              <h1>Shopping Cart</h1>
              <ul className="breadcrumb" style={{display: 'block'}}>
                <li><a href="/" style={{color:'#fff'}}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/viewcart"><span>Your Shopping Cart</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="checkout-cart" className="container" style={{marginBottom:100}}>
          {/* Breadcumb area start */}
          {/*End Breadcumb area */}
          <div className="row">
            <div id="content" className="col-sm-12">
              <form action="/checkout" method="post" id="cart_form">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td className="text-center">Image</td>
                        <td className="text-center">Product Name</td>
                        <td className="text-center">Quantity</td>
                        <td className="text-center">Unit Price</td>
                        <td className="text-center">Variations</td>
                        <td className="text-center">Total</td>
                      </tr>
                    </thead>
                    <tbody>
                    {props.carts.length > 0?
                      props.carts.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td className="text-center">
                              <a href={"/productsDescription/"+v.productId}>
                                {v.productImg?(
                                  <img style={{width: 100}} className="cart-image" src={v.productImg} alt="pracworks" />
                                ):<h3 style={{width: 100}}>No Image <br/>Preview</h3>}
                              </a>
                            </td>
                            <td className="text-center">
                              <a href={"/productsDescription/"+v.productId}>
                                {v.name}
                              </a>
                            </td>
                            <td className="text-left">
                              <div className="quantity-box" style={{borderRadius:0}}>
                                <input type="number" value={v.quantity} id="Quantity" readOnly className="form-control" />
                                <input type="button" onClick={() => props.updateCarts(v, i, 1, "+")} id="plus" value="+" className="form-control" />
                                <input type="button" onClick={() => minusCart(v, i, 1, "-")} id="minus" value="-" className="form-control" />
                              </div>
                              <button 
                                type="button" 
                                className="btn btn-danger"
                                onClick={() => props.removeCarts(i)}>
                                  <i className="fa fa-times-circle" />
                                </button>
                            </td>
                            <td className="text-right">
                              <span className="money">
                                {props.currency === "USD"? 
                                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.perUnitAmount)
                                  :
                                  new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v.perUnitAmount*props.rateUSDtoMYR))
                                }
                              </span>
                            </td>
                            <td className="text-left">
                              {v.variations?
                                v.variations.length > 0? (
                                  <ul className="mb-2 text-capitalize">
                                    {v.variations.map((v1, i1) => {
                                      return (
                                        <li key={i1}>- {v1.value} (
                                          <p className="cart-price mb-0 d-inline-block">
                                            <span className="money">
                                            +{
                                              props.currency === "USD"? 
                                                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.totalAmount)
                                                :
                                                new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.totalAmount*props.rateUSDtoMYR))
                                            }
                                            </span>
                                          </p>
                                        )</li>
                                      )
                                    })}
                                  </ul>
                                )
                                :null
                              :null}
                            </td>
                            <td className="text-right">
                              <span className="money">
                                {props.currency === "USD"? 
                                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                    v.totalNetAmount+(!isEmpty(v.variations)?sumBy(v.variations, "totalAmount"):0)
                                  )
                                  :
                                  new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                                    ((v.totalNetAmount+(!isEmpty(v.variations)?sumBy(v.variations, "totalAmount"):0))*props.rateUSDtoMYR)
                                  )
                                }
                              </span>
                            </td>
                          </tr>
                          )
                        })
                      : <h3 className="text-center border-bottom pt-2 pb-4">Empty Cart</h3>}
                    </tbody>
                  </table>
                </div>
                <div className="row justify-content-end">
                  <div className="col-sm-4 col-sm-offset-8">
                    <table className="table table-bordered">
                      <tbody><tr>
                          <td colSpan={2} className="text-center"><strong>Shipping, taxes, and discounts will be calculated at checkout.</strong></td>
                        </tr>
                        <tr>
                          <td className="text-right"><strong>Subtotal:</strong></td>
                          <td className="text-right">
                            <span className="total-price">
                              <span className="money">
                                {props.currency === "USD"? 
                                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                    sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount")))
                                  )
                                  :
                                  new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                                    ((sumBy(props.carts, "totalNetAmount")+sumBy(props.carts.filter(element => !isEmpty(element.variations)).map(sum => sumBy(sum.variations, "totalAmount"))))*props.rateUSDtoMYR)
                                  )
                                }
                              </span>
                            </span>
                          </td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
                <div className="buttons clearfix">
                  <div className="pull-left">
                    <a href="/products" className="btn btn-warning">Continue Shopping</a>
                  </div>
                  <div className="pull-right">
                    <a href="/checkout" className="btn btn-primary">PROCEED TO CHECK OUT</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
 
const mapStateToProps = state => ({
  carts: state.carts.data,
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
  currency: state.setting.currency
});

const mapDispatchToProps = {
  updateCarts: updateCarts,
  removeCarts: removeCarts
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewCart);