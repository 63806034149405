import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Alerts from "./Alerts.js";

import client from "../../feathers.js"

import PracWorksLogo from '../../assets/images/17884401_130907670782354_5641047300408986097_n_footer.png'

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required('User Name is required'),
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required')
})

const ModalSignIn = (props) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('danger')

  const onSubmit = (value) => {
    client.authenticate()
    .then(()=>{
      return client.service('users').create(value)
    })
    .then((res) => {
      handleOpen(true, "success", "Registered Successfully!")
      setTimeout(() => {
        setIsOpen(false)
        props.toggleSwitch()
      }, 
        500
      );
    })
    .catch((err)=>{
      handleOpen(true, "danger", err.message)
    })
  }

  const handleOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }

  return (
    <div>
      <Modal isOpen={props.visible} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Sign Up</ModalHeader>
        <ModalBody>
          <div className="well">
            {/* <h2>Returning Customer</h2>
            <p><strong>I am a returning customer</strong></p> */}
            <div style={{textAlign:'center'}} className="pb-3">
              <img 
                src={PracWorksLogo} 
                style={{width: 192}}
                alt="pracWorks Carbon" 
                />
            </div>
            <Alerts isOpen={isOpen} handleOpen={handleOpen} color={color} message={message} />
            <Formik
              initialValues={{
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
                firstName: "",
                lastName: "",
                gender: "male",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              render={({ errors, status, touched }) => (
                <Form className="pt-3">
                  <div className="form-group">
                    <Field 
                      type="text" 
                      name="username" 
                      placeholder="Username" 
                      className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')}  
                      />
                    <ErrorMessage name="username" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <Field 
                      type="email" 
                      name="email" 
                      placeholder="Email" 
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}  
                      />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <Field 
                      type="password" 
                      name="password" 
                      placeholder="Password" 
                      className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}  
                      />
                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <Field 
                      type="password" 
                      name="confirmPassword" 
                      placeholder="Confirm Password" 
                      className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}  
                      />
                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <Field 
                      type="text" 
                      name="firstName" 
                      placeholder="First Name" 
                      className='form-control'
                      />
                  </div>
                  <div className="form-group">
                    <Field 
                      type="text" 
                      name="lastName" 
                      placeholder="Last Name" 
                      className='form-control'
                      />
                  </div>
                  <div className="form-group">
                    <Field 
                      as="select"
                      name="gender" 
                      placeholder="Gender" 
                      className='form-control'
                    >
                      <option value="male">male</option>
                      <option value="female">female</option>
                      <option value="other">other</option>
                    </Field>
                  </div>
                  <div style={{textAlign:'center'}}>
                    <p 
                      style={{color: '#007bff', textDecoration: 'underline', cursor:'pointer'}}
                      onClick={props.toggleSwitch}>
                      Already have an account?</p>
                    <input type="submit" value="Sign Up" className="btn btn-primary" />
                  </div>
                </Form>
              )}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalSignIn);