import React, { useState } from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardFooter,
  Table
} from "reactstrap";

import InvoiceModalView from "./InvoiceModalView.js"

var pattern = "0000";

const Purchases = ({invoices}) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ dataEdit, setDataEdit ] = useState({})

  const toggleModal = () => {
    setMmodalVisible(!modalVisible)
  }

  return (  
    <>
     <div className="shadow card">
        <div className="border-0 card-header">
          <div className="align-items-center row">
            <div className="col-8">
              <h3 className="mb-0">My Purchases</h3>
            </div>
            {/* <div className="text-right col-4"><a href="#pablo" className="btn btn-primary btn-sm">Settings</a></div> */}
          </div>
        </div>
        <div className="card-body">
          <Card className="bg-default shadow">
            <div className="table-responsive mac-scrollbar">
              <Table className="align-items-center table-flush">
                <thead>
                  <tr>
                    <th scope="col">Inv.</th>
                    <th scope="col">Status</th>
                    <th scope="col">Tracking Num.</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Items Amt.</th>
                    <th scope="col">Shipping Amt.</th>
                    <th scope="col">Total Amt.</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {invoices.length > 0? (
                    invoices.map((v, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">
                            {v.invoicePrefix + " " + (pattern + v.invoiceNumber).slice(-4)}
                          </th>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i className={v.trackingNumber? "bg-success": "bg-danger"} />
                              {v.trackingNumber?"Completed":"Pending"}
                            </Badge>
                          </td>
                          <td>
                            {v.trackingNumber?v.trackingNumber:'-'}
                          </td>
                          <td>{v.currency}</td>
                          <td>
                            {v.currency === "USD"?
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.totalItemsAmount)
                            :
                            new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.totalItemsAmount)
                            }
                          </td>
                          <td>
                            {v.currency === "USD"?
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.shippingAmount)
                            :
                            new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.shippingAmount)
                            }
                          </td>
                          <td>
                            {v.currency === "USD"?
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.totalAmount)
                            :
                            new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.totalAmount)
                            }
                          </td>
                          <td className="text-right">
                            <button 
                              className="button" 
                              style={{padding: '0px 22px'}}
                              onClick={() => {setDataEdit(v); toggleModal()}}
                            >View</button>
                          </td>
                        </tr>
                      )
                    })
                  ): <span style={{padding: '0px 10px'}}>No Record Found!</span>}
                </tbody>
              </Table>
            </div>
            <CardFooter className="py-4 bg-default">
              <nav aria-label="...">
                {/* <PaginationRender /> */}
              </nav>
            </CardFooter>
          </Card>
        </div>
      </div>
      <InvoiceModalView
        dataEdit={dataEdit} 
        modalVisible={modalVisible} 
        toggleModal={toggleModal}
      />
    </>
  );
}
 
export default Purchases;