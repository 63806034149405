import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import ReactImageZoom from 'react-image-zoom';

const PreviewModal = (props) => {

  const prevImg = () => {
    if((props.active-1) >= 0){
      props.handleChangeImg(props.active-1)
    }
  }

  const nextImg = () => {
    if((props.active+1) < props.productImg.length)
    props.handleChangeImg(props.active+1)
  }

  return (
    <div>
      <Modal isOpen={props.visible} toggle={props.toggle} className="product-modal-xl" 
      // style={{minWidth:props.imgWidth}}
      >
        <ModalHeader toggle={props.toggle}>Image Preview</ModalHeader>
        <ModalBody>
          <div className="modal-preview-img">
            {((props.active-1) >= 0)?(
              <button onClick={prevImg}>
                <i className="fa fa-angle-left"></i>
              </button>
            ):(
              null
            )}
            {((props.active+1) < props.productImg.length)?(
              <button onClick={nextImg} className="modal-preview-next">
                <i className="fa fa-angle-right"></i>
              </button>
            ):(
              null
            )}
          </div>
          {props.productImg?
            props.productImg[props.active]?
            (
              // <ReactImageZoom {...{
              //   width: props.imgWidth, 
              //   // zoomWidth: 500, 
              //   zoomPosition: 'original',
              //   img: props.productImg[props.active].fileUrl
              // }} />
              <img src={props.productImg[props.active].fileUrl} alt="" className="w-100" />
            ):<h1>No Image Preview</h1>
          :null}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default PreviewModal;