import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { pushCarts } from '../redux/actions/cartsActions';
// import ReactImageZoom from 'react-image-zoom';
import classnames from "classnames"
import isEmpty from "lodash/isEmpty"
import uniqBy from "lodash/uniqBy"
import sumBy from "lodash/sumBy"
import cloneDeep from "lodash/cloneDeep"

import { 
  Media
} from 'reactstrap';

// import classnames from "classnames"
import NavBar from "../components/NavBar/Main.js"
import Footer from "../components/Footer/Main.js"

import PreviewModal from "../components/Extra/PreviewModal.js"

import client from "../feathers"

const ProductDesc = (props) => {
  const [ data, setData ] = useState({productImg:[]})
  const [ active, setActive ] = useState(0)
  const [ quantity, setQuantity ] = useState(1)
  const [ loading, setLoading ] = useState(false)
  const [ visiblePreview, setVisiblePreview ] = useState(false)
  const [ imgHeight, setImgHeight ] = useState(0)
  const [ imgWidth, setImgWidth ] = useState(0)

  const togglePreview = () => {
    setVisiblePreview(!visiblePreview)
  }

  useEffect(() => {
    // console.log(props.match.params.id)
    async function reFetchData(){
      let reFetch = await fetchData(
        props.match.params.id, 
        setData, 
        setImgHeight,
        setImgWidth,
        setLoading,
        initProductSliders,
        initCartButton,
      )
      
      if(!reFetch){
        console.log(reFetch)
        setTimeout(async function(){ 
          reFetch = await fetchData(
            props.match.params.id, 
            setData, 
            setImgHeight,
            setImgWidth,
            setLoading,
            initProductSliders,
            initCartButton,
          )
          if(!reFetch){
            console.log(reFetch)
            setTimeout(async function(){
              reFetch = await fetchData(
                props.match.params.id, 
                setData, 
                setImgHeight,
                setImgWidth,
                setLoading,
                initProductSliders,
                initCartButton,
              )
              if(!reFetch){ 
                console.log(reFetch)
              setTimeout(async function(){ 
                reFetch = await fetchData(
                  props.match.params.id, 
                  setData, 
                  setImgHeight,
                  setImgWidth,
                  setLoading,
                  initProductSliders,
                  initCartButton,
                )
                if(!reFetch){
                  console.log(reFetch)
                  setTimeout(async function(){ 
                    reFetch = await fetchData(
                      props.match.params.id, 
                      setData, 
                      setImgHeight,
                      setImgWidth,
                      setLoading,
                      initProductSliders,
                      initCartButton,
                    )
                    console.log(reFetch)
                  }, 2000);
                }
              }, 2000);
              }
            }, 2000);
          }
        }, 2000);
      }
    }
    reFetchData()

  }, [props.match.params.id])

  const fetchData = (id, 
    setData, 
    setImgHeight,
    setImgWidth,
    setLoading,
    initProductSliders,
    initCartButton) => {
    return client.service('products').get(id)
    .then((res) => {

      if(res.productImg){
        if(res.productImg[0]){
          var img = new Image();

          img.onload = function(){
            var height = img.height;
            var width = img.width;
            
            // code here to use the dimensions
            setImgHeight(height)
            setImgWidth(width)
          }
          
          img.src = res.productImg[0].fileUrl;
        }
      }

      if(res.variations){
        if(res.variations.length > 0){
          let uniqTypes = uniqBy(res.variations, "type")
          res.variations.map(v1 => {
            uniqTypes.map(uniType => {
              if(uniType._id === v1._id){
                v1.selected = true
                return v1
              }else{return v1}
            })
            return v1
          })
        }
      }
      setData(res)
      setLoading(true)
      initProductSliders(res.productImg)
      initCartButton()
      return true
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        console.log(true, 'danger', "Please Sign-in to continue!")
        return true
      }else if(err.name === "Timeout"){
        return false
      }else{
        console.log(true, 'danger', err.message)
        return true
      }
    })
  }

  const handleChangeImg = (i) => {
    setActive(i)
    if(data.productImg){
      if(data.productImg[i]){
        var img = new Image();

        img.onload = function(){
          var height = img.height;
          var width = img.width;
          
          // code here to use the dimensions
          setImgHeight(height)
          setImgWidth(width)
        }
        
        img.src = data.productImg[i].fileUrl;
      }
    }
  }

  const initProductSliders = (productImg) => {
    if(!isEmpty(productImg)){
      setTimeout(
        () => {
          var $ = window.$
          
          $(document).ready(function(){
            if ($(".owl-carousel")[0]){
              // Do something if class exists
              $('.owl-carousel').owlCarousel({
                items: 4,
                margin: 30,
                nav: true,
                responsiveClass:true,
                responsive:{
                  0:{
                    items:4,
                  },
                  768:{
                    items:4,
                  },
                  // 1024:{
                  //   items:4,
                  // }
                }
                // autoWidth: true,
                // videoWidth: true,
                // info: true,
                // nestedItemSelector: true,
              });

              function findActive(type){
                let owlStage = $( ".owl-stage" ).children()

                for(let i = 0; i < owlStage.length; i++){
                  if(owlStage[i].children[0].children[0].className.includes('current-additional')){
                    if(type === '+'){
                      if(i !== undefined && (i+1 < owlStage.length)){
                        setActive(i+1)
                      }
                    }else{
                      if(i !== undefined && (i-1 >= 0)){
                        setActive(i-1)
                      }
                    }
                    return i
                  }
                }
              }

              $( ".owl-nav .owl-next" ).on( "click", function() {
                findActive('+')
              });
              $( ".owl-nav .owl-prev" ).on( "click", function() {
                findActive('-')
              });

            }
          });
        }
        ,500
      );
    }
  }

  const initCartButton = () => {
    setTimeout(
      () => {
        var $ = window.$
    
        $(document).ready(function(){
          if ($(".add-to-cart-button")[0]){
            document.querySelectorAll('.add-to-cart-button').forEach(function(addToCartButton) {
              addToCartButton.addEventListener('click', function() {
                addToCartButton.classList.add('added');
                setTimeout(function(){
                  addToCartButton.classList.remove('added');
                }, 2000);
              });
            });
          }
        });
      }
      ,500
    ); 
  }

  const minusQuantity = () => {
    if(quantity > 1){
      setQuantity(quantity-1)
    }
  }

  const plusQuantity = () => {
    setQuantity(quantity+1)
  }

  const addToCart = (v, quantity) => {
    if(loading){
      if(v.status){
        props.pushCarts(v, quantity)
      }else{
        alert('Sorry, this item has been discontinued')
      }
    }
  }
  
  const onChangeVariation = (data, type, value) => {
    let dataClone = cloneDeep(data)
    let removeSelected = dataClone.variations.map(v => {
      if(v.type === type){
        delete v.selected
        return v
      }else{return v}
    })
    removeSelected.map(v => {
      if(v.type === type){
        if(v.value === value){
          v.selected = true
          return v
        }else{ return v }
      }else{ return v }
    })
    dataClone.variations = removeSelected
    setData(dataClone)
  }

  return (  
    <>
      <NavBar />
      <main id="content">
        <div className="container">
          <div className="custom-container">
            <div className="row">
              <div id="content" className="col-sm-12">
                <div className="row">
                  <div className="col-sm-5 block-1 owl-style2">
                    <div style={{cursor:'pointer'}} className="react-image-zoom-thumbnails">
                      {data.productImg?
                        data.productImg[active]?
                        (
                          // <ReactImageZoom {...{
                          //   width: 400, 
                          //   zoomWidth: 500, 
                          //   img: data.productImg[active].fileUrl
                          // }} />
                          <div onClick={togglePreview}>
                            <img src={data.productImg[active].fileUrl} alt="" className="w-100" />
                          </div>
                        ):<h1>No Image Preview</h1>
                      :null}
                    </div>
                    <div className="image-additional-container owl-style3">
                      <div className="thumbnails-additional owl-carousel owl-theme">
                        {!isEmpty(data.productImg)?
                          data.productImg.map((v, i) => {
                            return (
                              <div key={i}>
                                <div 
                                className={classnames("thumbnail", {
                                  "current-additional": i === active
                                })} 
                                onClick={() => handleChangeImg(i)}>
                                  <img 
                                    src={v.fileUrl}
                                    alt="pracWorks" />
                                </div>
                              </div>
                            )
                          })
                        :null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7 block-2 product-info-main">
                    <h1 className="product-name" itemProp="name">
                      {data.name}
                    </h1>
                    <p className="product-code">
                      {data.code}
                    </p>
                    {/* <div className="ratings">
                      <div className="rating-box">
                        <span className="spr-badge" id="spr_badge_516676321316" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star" /><i
                              className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i
                              className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /></span><span
                            className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                    </div> */}
                    {/* end-rating */}
                    <div className="price-box box-regular">
                      <span className="regular-price">
                        <span className="price" id="productPrice">
                          <span className="visually-hidden">
                            <span className="money">
                              {props.currency === "USD"? 
                                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.netAmount)
                                :
                                new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(((data.netAmount)*props.rateUSDtoMYR))
                                }
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="box-options">
                      <ul className="list-unstyled">
                        {/* <li>Brand:
                          <a href="/#"><span className="ex-text">Toyoto</span></a>
                        </li>
                        <link itemProp="availability" href="http://schema.org/InStock" /> */}
                        <li>Availability:
                          <span className="ex-text">
                            {data.availability}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="short-des" style={{whiteSpace: 'pre-line'}}>
                      <p className="intro">
                        {data.description}
                      </p>
                    </div>
                    <div>
                      {data.variations?
                        data.variations.length > 0?
                          uniqBy(data.variations, "type").map((uniType, uniIndex) => {
                            return (
                              <div key={uniIndex} className="mt-2">
                                <Media>
                                  <Media body>
                                    <h4 className="text-uppercase">{uniType.type.split('_').join(' ')}</h4>
                                  </Media>
                                  <Media body className="text-right">
                                    <div className="price-box mt-0">
                                      <p className="special-price">
                                        <span className="price" style={{fontSize:14}}>
                                          <span className="money">+{" "}
                                            {data.variations.map((v1) => {
                                              if(v1.type === uniType.type){
                                                if(v1.selected){
                                                  return (props.currency === "USD"? 
                                                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.amount)
                                                    :
                                                    new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.amount*props.rateUSDtoMYR))
                                                  )
                                                }else{return false}
                                              }else{return false}
                                            })}
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                  </Media>
                                </Media>
                                <select onChange={(e)=>onChangeVariation(data, uniType.type, e.target.value)} className="form-control text-capitalize">
                                  {data.variations.map((v1, i1) => {
                                    if(v1.type === uniType.type){
                                      return (
                                        <option key={i1} value={v1.value}>{v1.value}
                                        (+{
                                          props.currency === "USD"? 
                                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v1.amount)
                                            :
                                            new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v1.amount*props.rateUSDtoMYR))
                                        })
                                        </option>
                                      )
                                    }else{return false}
                                  })
                                  }
                                </select>
                              </div>
                            )
                          })
                        :null
                      :null}
                    </div>
                    <div className="price-box box-regular">
                      <span className="regular-price">
                        <span className="price" id="productPrice">
                          <span className="visually-hidden">
                            <span className="money">Total:{" "}
                              {props.currency === "USD"? 
                                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                  (data.netAmount+(!isEmpty(data.variations)? sumBy(data.variations.filter(e => e.selected), "amount"):0 ))*quantity
                                )
                                :
                                new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                                  (((data.netAmount+(!isEmpty(data.variations)? sumBy(data.variations.filter(e => e.selected), "amount"):0 ))*quantity)*props.rateUSDtoMYR)
                                )
                              }
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div id="product">
                      <form id="form_buy">
                        <div className="form-group">
                          <label className="control-label" htmlFor="Quantity">Qty:</label>
                          <div className="quantity-box">
                            <input type="number" value={quantity} id="Quantity" readOnly className="form-control" />
                            <input type="button" onClick={() => plusQuantity()} id="plus" value="+" className="form-control" />
                            <input type="button" onClick={() => minusQuantity()} id="minus" value="-" className="form-control" />
                          </div>
                          <span id="variantQuantity" className="variant-quantity" />
                          <button 
                            className="button button-cart btn add-to-cart-button" 
                            type="button" 
                            id="button-cart"
                            data-loading-text="Loading..."
                            onClick={() => addToCart(data, quantity)}>
                              <svg className="tick" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="#ffffff" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"/></svg>
                              <span className="add-to-cart">Add to cart</span>
                              <span className="added-to-cart">Added to cart</span>
                          </button>
                          {/* <button className="button btn-wishlist" type="button" data-toggle="tooltip" title
                            data-original-title="Add to Wish List" onclick="location.href='/account/login'">
                            <i className="ion-android-favorite-outline" /><span>Add to Wish List</span>
                          </button> */}
                        </div>
                      </form>
                      {/* end-button */}
                    </div>
                  </div>
                  {/* <div className="block-3 col-sm-12 product-info-detailed">
                    <ul className="nav nav-tabs">
                      <li role="presentation" className="active">
                        <a href="/#description" aria-controls="description" role="tab" data-toggle="tab"
                          aria-expanded="true">Description</a>
                      </li>
                      <li role="presentation" className><a href="#reviews" aria-controls="reviews" role="tab" data-toggle="tab"
                          aria-expanded="false">Reviews</a></li>
                      <li role="presentation" className><a href="#data" aria-controls="data" role="tab" data-toggle="tab"
                          aria-expanded="false">Additional Information</a></li>
                      <li role="presentation" className>
                        <a href="#tags" aria-controls="tags" role="tab" data-toggle="tab" aria-expanded="false">Tags</a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div role="tabpanel" className="tab-pane active" id="description">
                        <p>
                          <strong>Born to be worn.</strong><span />
                        </p>
                        <p>Clip on the worlds most wearable music player and take up to 240 songs with you anywhere. Choose from
                          five colors including four new hues to make your musical fashion statement.</p>
                        <p><strong>Random meets rhythm.</strong></p>
                        <p>With iTunes autofill, iPod shuffle can deliver a new musical experience every time you sync. For more
                          randomness, you can shuffle songs during playback with the slide of a switch.</p>
                        <strong>Everything is easy.</strong><span />
                        <p>Charge and sync with the included USB dock. Operate the iPod shuffle controls with one hand. Enjoy up
                          to 12 hours straight of skip-free music playback.</p>
                        <p><b>Intel Core 2 Duo processor</b></p>
                        <p>Powered by an Intel Core 2 Duo processor at speeds up to 2.16GHz, the new MacBook is the fastest ever.
                        </p>
                        <p><b>1GB memory, larger hard drives</b></p>
                        <p>The new MacBook now comes with 1GB of memory standard and larger hard drives for the entire line
                          perfect for running more of your favorite applications and storing growing media collections.</p>
                        <p><b>Sleek, 1.08-inch-thin design</b></p>
                        <p>MacBook makes it easy to hit the road thanks to its tough polycarbonate case, built-in wireless
                          technologies, and innovative MagSafe Power Adapter that releases automatically if someone accidentally
                          trips on the cord.</p>
                        <p><b>Built-in iSight camera</b></p>
                        <p>Right out of the box, you can have a video chat with friends or family,2 record a video at your desk,
                          or take fun pictures with Photo Booth</p>
                        <p />
                      </div>
                      <div role="tabpanel" className="tab-pane" id="reviews">
                        <div>
                          <div className="spr-container">
                            <div className="spr-header">
                              <h2 className="spr-header-title">Customer Reviews</h2><div className="spr-summary">
                                <span className="spr-starrating spr-summary-starrating">
                                  <i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" />
                                </span>
                                <span className="spr-summary-caption"><span className="spr-summary-actions-togglereviews">Based on 1 review</span>
                                </span><span className="spr-summary-actions">
                                  <a href="#" className="spr-summary-actions-newreview" onclick="SPR.toggleForm(516676321316);return false">Write a review</a>
                                </span>
                              </div>
                            </div>
                            <div className="spr-content">
                              <div className="spr-form" id="form_516676321316" style={{display: 'none'}}><form method="post" action="//productreviews.shopifycdn.com/api/reviews/create" id="new-review-form_516676321316" className="new-review-form" onsubmit="SPR.submitForm(this);return false;"><input type="hidden" name="review[rating]" /><input type="hidden" name="product_id" defaultValue={516676321316} /><h3 className="spr-form-title">Write a review</h3><fieldset className="spr-form-contact"><div className="spr-form-contact-name">
                                      <label className="spr-form-label" htmlFor="review_author_516676321316">Name</label>
                                      <input className="spr-form-input spr-form-input-text " id="review_author_516676321316" type="text" name="review[author]" defaultValue placeholder="Enter your name" />
                                    </div><div className="spr-form-contact-email">
                                      <label className="spr-form-label" htmlFor="review_email_516676321316">Email</label>
                                      <input className="spr-form-input spr-form-input-email " id="review_email_516676321316" type="email" name="review[email]" defaultValue placeholder="john.smith@example.com" />
                                    </div></fieldset>
                                  <fieldset className="spr-form-review">
                                    <div className="spr-form-review-rating">
                                      <label className="spr-form-label" htmlFor="review[rating]">Rating</label>
                                      <div className="spr-form-input spr-starrating ">
                                        <a href="#" onclick="SPR.setRating(this);return false;" className="spr-icon spr-icon-star spr-icon-star-empty" data-value={1} aria-label="1 of 5 stars">&nbsp;</a>
                                        <a href="#" onclick="SPR.setRating(this);return false;" className="spr-icon spr-icon-star spr-icon-star-empty" data-value={2} aria-label="2 of 5 stars">&nbsp;</a>
                                        <a href="#" onclick="SPR.setRating(this);return false;" className="spr-icon spr-icon-star spr-icon-star-empty" data-value={3} aria-label="3 of 5 stars">&nbsp;</a>
                                        <a href="#" onclick="SPR.setRating(this);return false;" className="spr-icon spr-icon-star spr-icon-star-empty" data-value={4} aria-label="4 of 5 stars">&nbsp;</a>
                                        <a href="#" onclick="SPR.setRating(this);return false;" className="spr-icon spr-icon-star spr-icon-star-empty" data-value={5} aria-label="5 of 5 stars">&nbsp;</a>
                                      </div>
                                    </div>
                                    <div className="spr-form-review-title">
                                      <label className="spr-form-label" htmlFor="review_title_516676321316">Review Title</label>
                                      <input className="spr-form-input spr-form-input-text " id="review_title_516676321316" type="text" name="review[title]" defaultValue placeholder="Give your review a title" />
                                    </div>
                                    <div className="spr-form-review-body">
                                      <label className="spr-form-label" htmlFor="review_body_516676321316">
                                        Body of Review
                                        <span role="status" aria-live="polite" aria-atomic="true">
                                          <span className="spr-form-review-body-charactersremaining">(1500)</span>
                                          <span className="visuallyhidden">characters remaining</span>
                                        </span>
                                      </label>
                                      <div className="spr-form-input">
                                        <textarea className="spr-form-input spr-form-input-textarea " id="review_body_516676321316" data-product-id={516676321316} name="review[body]" rows={10} placeholder="Write your comments here" defaultValue={""} />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="spr-form-actions">
                                    <input type="submit" className="spr-button spr-button-primary button button-primary btn btn-primary" defaultValue="Submit Review" />
                                  </fieldset></form></div>
                              <div className="spr-reviews" id="reviews_516676321316"><div className="spr-review" id="spr-review-80408521">
                                  <div className="spr-review-header">
                                    <span className="spr-starratings spr-review-header-starratings" aria-label="5 of 5 stars" role="img"><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /><i className="spr-icon spr-icon-star" /></span>
                                    <h3 className="spr-review-header-title">Good</h3>
                                    <span className="spr-review-header-byline"><strong>HVP</strong> on <strong>Nov 21, 2019</strong></span>
                                  </div>
                                  <div className="spr-review-content">
                                    <p className="spr-review-content-body">Good job</p>
                                  </div><div className="spr-review-footer">
                                    <a href="#" className="spr-review-reportreview" onclick="SPR.reportReview(80408521);return false" id="report_80408521" data-msg="This review has been reported">Report as Inappropriate</a>
                                  </div>
                                </div></div>
                            </div>
                          </div></div>
                        <p />
                      </div>
                      <div role="tabpanel" className="tab-pane" id="data">
                        <p>You can use this tab for adding shipping details, return policies and information or measurements etc. </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Typi</p>
                      </div>
                      <div role="tabpanel" className="tab-pane" id="tags">
                        <p className="tags">
                          <span>Tags: 
                            <a href="/collections/all/auto">auto</a>
                            , 
                            <a href="/collections/all/car">car</a>
                            , 
                            <a href="/collections/all/original">original</a>
                            , 
                            <a href="/collections/all/spare parts">spare parts</a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <PreviewModal 
        visible={visiblePreview}
        toggle={togglePreview}
        productImg={data.productImg}
        handleChangeImg={handleChangeImg}
        active={active}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
      />
    </>
  );
}
 

const mapStateToProps = state => ({
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
  currency: state.setting.currency
});

const mapDispatchToProps = {
  pushCarts: pushCarts
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDesc);