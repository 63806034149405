import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { updateDetails } from '../redux/actions/roleActions';
import { logout } from '../redux/actions/roleActions';
import NavBar from "../components/NavBar/Main.js"
import Footer from "../components/Footer/Main.js"

import ProfileForm from "../components/Profile/ProfileForm.js"
import Purchases from "../components/Profile/Purchases.js"

import { ListGroup, ListGroupItem } from 'reactstrap';

import client from "../feathers.js"

const Profile = (props) => {
  const [ current, setCurrent ] = useState(0)
  const [ invoices, setInvoices ] = useState([])
  
  useEffect(() => {
    client.authenticate()
    .then(() => {
      return client.service('invoices').find({
        query: {
          userId: props.userId,
          // $skip: skip,
          // $limit: 20,
          $sort: {
            createdAt: -1
          }
        }
      })
    })
    .then((res) => {
      setInvoices(res.data)
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        console.log(true, 'danger', "Please Sign-in to continue!")
      }else{
        console.log(true, 'danger', err.message)
      }
    })
  }, [props.userId])

  const logout = () => {
    client.authenticate()
    .then((a) => {
      client.logout()
      props.logout()
      props.history.push('/')
    })
    .catch((err) => {
      props.logout()
      props.history.push('/')
    })
  }

  return (  
    <>
      <NavBar />
      <main>
        <div
          style={{
            backgroundImage: 'url(//cdn.shopify.com/s/files/1/3012/8606/t/4/assets/collection_top.jpg?v=13539636231063041806)', 
            position:'relative', 
            marginBottom: 60,
            minHeight: 530
          }}
        >
          <div className="breadcrumbs">
            <div className="container" style={{paddingRight: 60, paddingLeft: 60}}>
              <h1>Profile</h1>
              <ul className="breadcrumb" style={{display: 'block'}}>
                <li><a href="/" style={{color:'#fff'}}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/viewcart"><span>Profile</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container" style={{marginBottom:60}}>
          <section className="order_details section-margin--small">
            <div className="container">
              <div className="row justify-content-center">
                <div className="mb-5 mb-xl-0 col-md-4 col-xl-3">
                  <div className="card-profile shadow card">
                    <ListGroup>
                      <ListGroupItem className={current === 0?"active text-white":""} tag="a" onClick={() => setCurrent(0)} action>My Profile</ListGroupItem>
                      <ListGroupItem className={current === 1?"active text-white":""} tag="a" onClick={() => setCurrent(1)} action>My Purchases</ListGroupItem>
                      <ListGroupItem tag="a" onClick={logout} action>Logout</ListGroupItem>
                    </ListGroup>
                  </div>
                </div>
                <div className="col-md-8">
                  {current === 0? (
                    <ProfileForm 
                      auth={props.auth}
                      userId={props.userId}
                      userInfo={props.userInfo}
                      updateDetails={props.updateDetails}
                    />
                  ): (
                    <Purchases 
                      invoices={invoices}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        </main>
      <Footer />
    </>
  );
}
 

const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
  logout: logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);